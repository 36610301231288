body.modal-open {
  position: fixed;
  overflow: hidden;
}

.modal {
  overflow-y: scroll;
}

.modal-dialog {
  margin: 0 auto;
    height: auto;
}

.modal-event-open {
  .modal-backdrop.in {
    filter: sepia(0%) saturate(-100%) hue-rotate(180deg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    &::after {
      content: '';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      background: $color-dark-blue;
      opacity: 0.125;
    }
  }
  .modal-dialog {
    margin: 0 auto;
      height: auto;
  width: 480px;
  }
  

}

.modal-event-open {

  .modal-content {

    display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      -webkit-box-orient: vertical;
      -ms-flex-direction: column;
      flex-direction: column;

      min-height: 100%;
     // background-color: white;
  }

}


.modal-open-filter {
  .modal-dialog {
    width:400px;
  }
}

.modal-open-center {

.modal {
  text-align: center;
  padding: 0!important;
  overflow-y: auto;
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

}

/*
.modal-dialog {
  margin: 0;
  height:100%;
  width: 100%;
}
*/
.modal-content {
  background-color: $color-darkest-blue;

    border: 0;
  border-radius: 0;
  box-shadow: none;

}

.modal-backdrop {
  background-color: $color-darkest-blue;
}


.modal-open-cover {
  .modal-backdrop.in {
    background-color: $color-darkest-blue;
    opacity: 0.25;
  }
}


.modal.fade .modal-dialog {
  transform: translate3d(0, 0, 0);
  -webkit-transition: 	all 0s ease 0s;
  -moz-transition:	all 0s ease 0s;
  transition: 	all 0s ease 0s;
  opacity: 1;
}

.modal-gendre-picker {
  .modal-content > div {
    max-height: 100%;
  }
}

.modal-event-open {
  #map-wrapper .markerx {
    opacity: 0;
  }
}



@media (min-width: 30rem) {
  .modal-backdrop.in {
    opacity: 0.5;

  }
  
  .modal-content {
    min-height: auto;

  }



}

@media (max-width: 29.99999rem) {
  .modal-open-center {
    .modal::before {
     content: none;
    }
  }

  .modal-open-cover {
    .modal::before {
      content: '';
     }
     .modal-backdrop.in {
      opacity: 0.5;
    }
  }

  .modal-open-center .modal-dialog {
    vertical-align: bottom;
  }
  
  .modal .modal-dialog {
    margin: 0;
    width: 100%;
  }

  .modal-open-fade .modal.fade .modal-dialog {
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    opacity: 1;
  }


.modal-event-open {
  .modal-backdrop.in {
    opacity:.8;
    &::after {
      background: $color-dark-blue;
      opacity: 1;
    }


  }

}
  .modal-event-open {

  .modal-dialog {
    height: 100%;
  }


    .modal-content {
      background-color: transparent;
    }

    .s-map-overlay {
      opacity: 0;
    }


   .sidebar {
    opacity: 0;
  }

  }


}

.modal-privacy {
  .modal-content {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
}