@import 'variables';

/*
.home-btn {
  background-size: 30px;

    background-color: $color-yellow;
    -webkit-mask-image: url('../assets/images/home-btn.svg');
    mask-image: url('../assets/images/home-btn.svg');

    -webkit-mask-size: 30px;
    mask-size: 30px;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}*/
.navbar-nav>li>.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100px;
    padding: 5px 0;
    margin: 10px 0 0;
    width: 100%;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    >li>button {
      &:hover {
        border-color: #208650;
        border-color: rgba(15, 252, 138, 1);
      }
      margin-bottom: 10px;
      padding-left:4px;
      padding-right:4px;
      width:100%;
      font-size: 14px;
    }
}

/** Main nav **/
.nav {
    > li {
        //margin: 12px 5px;
        position: relative;
        transition: color 0.25s;
    }
    button {
        /*border-image: radial-gradient(farthest-corner at 0 100%, #9DF6E4 30%, #0FFC8A) 1;
        border-style: solid;
        border-width: 2px;*/
        &:not(.btn-profile):hover {
            opacity: 1;
        }
    }
    .btn {
        padding-left: 20px;
        padding-right: 20px;
        height: 38px;
        font-size: 16px;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .btn-green-bold {
      background: #0FFC8A;
      color: #111;
      font-weight: 700;
      border-color: #0FFC8A;
    }
}

/** secondary nav **/
.modal-add-video .navbar-right,
.add-video .navbar-right,
.video-player .navbar-right,
.modal-settings .navbar-right {
    display: inline-block;
    right: 0;
}
/** settings nav **/
.navbar-nav-sub>li {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 50px;
    text-transform: uppercase;
}



.pagination-wrapper::before {
  /* content: ' '; */
  position: absolute;
  left: 0;
  right: .25rem;
  top: -20px;
  height:20px;
  z-index: 1;
  /* -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%); */
  background-image: linear-gradient(to top, $color-dark-blue-transparent 0%, transparent 100%);
}

.pagination a {
  color: white;
}

.pagination-past a {
  color: $color-green;
}

.pagination {
  .disabled {
    a {
      opacity: 0.5;
    }
  }
  .active {
    a {
      opacity: 0.5;
    }
  }
}

.date-block {
  flex: 0 0 3rem;
}

.sidebar {
  top: 4.5rem;
  transform: translate3d(-100%, 0, 0);
  //transition: transform 0.35s;
  &.open {
    transform: translate3d(0, 0, 0);
  }
}


@media screen and (min-width: 30em) {
  .event-browse-header {
    width: 320px;
  }
  .sidebar {
    width: 320px;
    transform: translate3d(0, 0, 0);
  }
}


.event-button-icon {
  > div {
    margin-right: 2rem;
  }
  &::after {
    content:'';
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3rem;
  }

  &.event-ticketed::after {
    background-size: 18px;
    background-image: url('../assets/images/icon-dialog.svg');
  }

  &.event-setlist::after {
    background-size: 25px;
    background-image: url('../assets/images/setlist-icon.png');
  }
}

.event-button-title {
  font-size: 1.125rem;
}

.event-button-title-md {
  font-size: 1.25rem;
}

.event-button-title-sm {
  font-size: 1.35rem;
}
/*
.event-list {
  &::after {
    content: '';
    display: block;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: none;
    background: linear-gradient(0deg, $color-dark-blue-transparent 0%, rgba(0,0,0,0) 100%);
  }
}*/

.event-list li {
  transition: margin 200ms, padding 200ms;
}
.event-list li:last-child .event-button {
  padding-bottom: 1.25rem;
}

.event-popup-list li:last-child {
  padding-bottom: 2rem;
}

/*.event-name {
  font-size:1.175rem;
}*/

.date-block {
  .month {
    font-size:.65rem;
  }
  .day {
    font-size:1.1rem;
    letter-spacing: .05em;
    &.eleven {
      letter-spacing: .1em;
    }
  }
  .year {
    margin-top: 0.125rem;
    font-size:.65rem;
  }
}
.event-name-s {
  font-size:1.1rem;
}
.event-name-l {
  font-size:1.095rem;
}
@media screen and (min-width: 30em) {
  /*.event-list {
    &::after {
      display: none;
    }
  }*/

  /*.highlight-el {
    .event-button:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }*/

  /*.event-name {
    font-size:1rem;
  }*/
}


.focused-el {
  padding-top: 2rem;
  padding-bottom: 3.5rem;
}

.event-list-location {
  li:first-of-type.focused-el {
    margin-top: 0;
  }
  li:not(:first-of-type).focused-el {
    margin-top: .5rem;
  }
}
.highlight-el-first {
  //margin-top: -45px;
  //padding-top: 14px;
}

.highlight-el-last {

  .event-button {
    padding-bottom: 1.25rem;
  }
}


.markerx.marker-single.marker-icon.marker-load {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: white;


  &::after {
    background: white;
  }
  .number {
    border:none;
    background-color: $color-darkest-blue;
    height: 21px;
    width: 21px;
  }
}

.map-bar-header {
  border-top-left-radius: 1rem;
}

.map-nav-zoom {
  transform: translate3d(0, calc(-100% - 0.5rem), 0);
  > button {
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
    &:before {
      content: '';
      position: absolute;
      top: -.25rem;
      bottom: -.25rem;
      left: -1rem;
      right: -1rem;
    }
    &:nth-child(2) {
      margin-top: .75rem;
      margin-bottom: .75rem;
    }

  }
}


/*
.s-nav-button {
  width: 2.75rem;
}

.s-nav-button > * {
  width: 1.5rem;
}

@media screen and (min-width: 30em) {
  .s-nav-button > * {
    width: 1.25rem;
  }
}*/

.s-nav-button > * {
  width: 1.75rem;
}

.s-home-button {
  padding:0.875rem;
  width: 3.25rem;
  height:3.25rem;
}

.s-map-toggle {
  width: 3.75rem;
  height: 3.125rem;
  svg {
    width: 1.75rem;
    overflow: visible;
  }
}

.event-browse-header {
  height: 4.5rem;
}

.map-sidebar-open {
  .markerx {
    opacity: 0;
  }
}

@media screen and (min-width: 30em) {
.map-sidebar-open {
    .markerx {
      opacity: 1;
    }
}

.event-browse-header {
  height: 4.25rem;
}

.sidebar {
  top: 4.25rem;
}
}




.s-button-standard, .s-button-secondary {
  min-width: 7rem;
  height: 2.5rem;
  font-size: 1rem;
}

.s-button-secondary {
  background-color: rgba(255,255,255,.1);
}

.s-search-nav {
  > button {
    font-size: 0.8rem;
    height: 2.5rem;
  }
}


.s-filter-nav {
  > button {
    min-width:6rem;
    height: 2rem;
  }
  > button:disabled,
  > button[disabled]{
    opacity: 0.5;
  }
}

.s-genre-nav {
  button {
    transition: background-color 50ms;
    background-color: rgba(255, 255, 255, 0.025);
  }
  button.genre-checked {
    background-color: rgba(48, 79, 105, 0.2);
    border-color: $color-mid-blue;
  }
}

.s-button-listen {
  height:1.5rem;
  > img {
    width:0.75rem;
    height:0.75rem;
  }
}

.s-event-action-nav {
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
  button {
    width: 2.5rem;
    height: 2.5rem;
  }
  .s-btn-tix {
    width: 3.25rem;
    height: 3.25rem;
    img {
    width: 1.25rem;
    height: 1.25rem;
  }}
  .s-btn-directions {

    img {
      width: 1.45rem;
      height: 1.45rem;
  
  }}

  .s-btn-popup {
    width: 3rem;
    height: 3rem;
    span {
      width: 1.25rem;
  }
  }
}

.s-event-nav-btm {
  button {
    min-width: 7rem;
    height: 2.5rem;
    font-size: .75rem;
    font-weight: bold;
  }
  .s-button-tickets {
    min-width: 10rem;
  }
}

@media screen and (min-width: 30em) {
  .s-button-standard, .s-button-secondary {
    min-width: 6rem;
    height: 2rem;
    font-size: .75rem;
  }
  
  .s-filter-nav {
    > button {
      height: 1.75rem;
    }
  }


.s-event-action-nav {
  position: absolute;
  top: -0.75rem;
  right: 0.5rem;
  button {
    width: 2.5rem;
    height: 2.5rem;
  img  {
    width: 0.875rem;
    height: 0.85rem;
  }
}
  .s-btn-marker {
    width: 1.75rem;
    height: 1.75rem;
  span {
    width: 1.1rem;
    height: 1.35rem;
  }
}
}

.highlight-el, .focused-el {
  .event-button {
  &::before {
    content: '';
  }
}
}

.focused-el {
  .event-button::before {
    background-color: $color-yellow;
  }
}

}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  li:first-child button {
    padding-top: 1rem;
  }
  button {
    padding: 0.75rem;
  }
}

.open>.dropdown-menu {
  display: block;
}

.s-event-name  {
  font-size: 1.125rem;
}

.s-keyword-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: .7; /* Firefox */
}

/* Change the white to any color */
.s-keyword-input:-webkit-autofill,
.s-keyword-input:-webkit-autofill:hover, 
.s-keyword-input:-webkit-autofill:focus, 
.s-keyword-input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgba(255,255,255,.1) inset !important;

    & + button > span > svg > path {
      fill: black;
    }
}

.s-link-popup {
  > span {
    width:.75rem;
    height:.75rem;
  }
}

.sidebar-events-container {
  &:after {
    content: '';
    position: absolute;
  left: 0;
  right: 0;
  bottom:0;
  height:70px;
  z-index: -1;
  /* -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%); */
  background-image: linear-gradient(to top, $color-dark-blue-transparent 0%, transparent 100%);
  }
}

@media screen and (min-width: 30em) {
  .sidebar-events-container {
    &:after {
      content: none;
    }
  }
}

.s-query-title {
  margin-top:.15rem;
  .s-icon-marker {
    width:.7rem;
  }
}


@media screen and (min-width: 30em) {
  .s-query-title {
    .s-icon-marker {
      margin-right: 0.4rem;
    }
  }
}