@media (min-width: 768px) {
  #coming-soon {
    display: none; } }

@media (max-width: 767px) {
  .nopadding {
    padding: 0 !important; } }

@media (min-height: 630px) and (min-width: 1314px) {
  .player-add-video #video-element {
    width: calc(854px + 300px); }
  .player-add-video .youtube-player-wrapper {
    width: 854px; }
  .add-video-selected-tracks {
    margin-top: calc(480px + 3rem); } }

@media (min-height: 854px) and (min-width: 1740px) {
  .player-add-video #video-element {
    width: calc(1280px + 300px);
    margin: 0 auto; }
  .player-add-video .youtube-player-wrapper {
    width: 1280px; }
  .add-video-selected-tracks {
    margin-top: calc(720px + 3rem); } }

/* Minimum aspect ratio */
@media (min-aspect-ratio: 16 / 8) {
  #video-element {
    width: 80%; } }

@media (min-aspect-ratio: 15 / 8) {
  #video-element {
    width: 84%; } }

html,
body {
  margin: 0;
  padding: 0; }

menu,
ol,
ul {
  list-style: none; }

hr,
html,
i,
img,
label,
li,
menu,
ol,
p,
small,
span,
strong,
sub,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
ul {
  font-size: 100%; }

fieldset {
  border: 0; }

*:focus {
  outline: 0 !important; }

* {
  margin: 0;
  padding: 0; }

/** main nav **/
.modal-add-video .navbar-map,
.add-video .navbar-map,
.video-player .navbar-map,
.modal-hidesidebar .navbar-map {
  display: none; }

button {
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  appearance: none; }

.w-1point5 {
  width: 1.5rem; }

.w-2point5 {
  width: 2.5rem; }

.w-3 {
  width: 3rem; }

.w-5 {
  width: 5rem; }

.w-2-5 {
  width: 2.5%; }

.w-95 {
  width: 95%; }

.w10 {
  width: 10rem; }

.w-16 {
  width: 16.66667%; }

.w-18 {
  width: 18rem; }

.w-20 {
  width: 20rem; }

.w-300 {
  width: 300px; }

.w-320 {
  width: 320px; }

.w-200 {
  width: 200px; }

.min-w-2 {
  min-width: 2rem; }

.mw-20 {
  max-width: 20rem; }

.mw-9 {
  max-width: 9rem; }

.h-1point5 {
  height: 1.5rem; }

.h-2point5 {
  height: 2.5rem; }

.h-3 {
  height: 3rem; }

.mh--1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem; }

.mr-3 {
  margin-right: 3rem; }

.pa05 {
  padding: 0.125rem; }

.pa2point5 {
  padding: 0.75rem; }

.pl1point5 {
  padding-left: 1.5rem; }

.pl-3 {
  padding-left: 3rem; }

.pl45 {
  padding-left: 3rem; }

.pv2-5 {
  padding-top: .65rem;
  padding-bottom: .65rem; }

.pb-05 {
  padding-bottom: .05rem; }

.pb-3 {
  padding-bottom: 3rem; }

@media screen and (min-width: 30em) {
  .pb0-ns {
    padding-bottom: 0; } }

.pr-1point5 {
  padding-right: 1.5rem; }

.top-4 {
  top: 4rem; }

.top-7 {
  top: 7rem; }

.bottom-3 {
  bottom: 3rem; }

.bottom-4 {
  bottom: 4rem; }

.fill-yellow {
  fill: #fcd500; }

.fill-white path {
  fill: white; }

.fill-darkest-blue path {
  fill: #0d161d; }

.fill-blue {
  fill: #b0fbff; }

.fill-green {
  fill: #0ffc8a; }

.stroke-blue {
  stroke: #b0fbff; }

.yellow {
  color: #fcd500; }

.green {
  color: #0ffc8a; }

.blue {
  color: #b0fbff; }

.bg-blue {
  background-color: #b0fbff; }

.bg-green {
  background-color: #0ffc8a; }

.bg-yellow {
  background-color: #fcd500; }

.bg-mid-blue {
  background-color: #304f69; }

.bg-mid-blue-transparent {
  background-color: rgba(48, 79, 105, 0.75); }

.bg-white-025 {
  background-color: rgba(255, 255, 255, 0.025); }

.b--blue {
  border-color: #b0fbff; }

.b--green {
  border-color: #0ffc8a; }

.b--yellow {
  border-color: #fcd500; }

.b--mid-blue {
  border-color: #304f69; }

.br-50 {
  border-radius: 50%; }

.br-3 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 5rem; }

.object-fit-cover {
  object-fit: cover; }

.pe-none {
  pointer-events: none; }

.pe-all {
  pointer-events: all; }

.z-1039 {
  z-index: 1039; }

.z-1051 {
  z-index: 1051; }

.cursor-none {
  cursor: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.aspect-ratio-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%); }

.roboto {
  font-family: 'Poppins', sans-serif; }

.oswald {
  font-family: 'Oswald', sans-serif; }

.f2point5 {
  font-size: 2.5rem; }

.f8 {
  font-size: 0.6875rem; }

.f9 {
  font-size: 0.55rem; }

@media screen and (min-width: 30em) {
  .f7-ns {
    font-size: .75rem; } }

.darkest-blue {
  color: #0d161d; }

.color-dark {
  color: #050b16; }

.color-green {
  color: #0ffc8a; }

.color-blue {
  color: #b0fbff; }

.color-deep-red {
  color: #ff0000; }

.color-red {
  color: #ff4141; }

.color-dark-blue {
  color: #0e1921; }

.color-dark-blue {
  color: #0e1921; }

.color-darkest-blue {
  color: #0d161d; }

.color-yellow {
  color: #fcd500; }

.color-dark-blue {
  color: #0e1921; }

.bg-tm {
  background-color: #026CDF; }

.bg-off-black {
  background-color: #050b16; }

.bg-dark-blue {
  background-color: #0e1921; }

.bg-darkest-blue {
  background-color: #0d161d; }

.bg-dark-blue-transparent {
  background-color: rgba(17, 26, 34, 0.9); }

.bg-darkest-blue-transparent {
  background-color: rgba(13, 22, 29, 0.9); }

@media screen and (min-width: 30em) {
  .bg-dark-blue-transparent-ns {
    background-color: rgba(17, 26, 34, 0.9); }
  .bg-darkest-blue-transparent {
    background-color: rgba(13, 22, 29, 0.9); }
  .bg-transparent-ns {
    background-color: transparent; } }

.top--100 {
  transform: translateY(-100%); }

.no-transition {
  transition-property: none !important; }

.sticky {
  position: sticky; }

.animate {
  transition: transform 0.5s ease; }

.pointer-events-all {
  pointer-events: all; }

.pointer-events-none {
  pointer-events: none; }

@media screen and (min-width: 30em) {
  .pointer-events-none-ns {
    pointer-events: none; } }

[disabled] {
  cursor: not-allowed; }

.stubborn {
  flex-shrink: 0; }

.hb-inner {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  display: block; }

.hb-inner, .hb-inner:after, .hb-inner:before {
  position: absolute;
  width: 28px;
  height: 2px;
  background-color: white; }

.hb-inner:after, .hb-inner:before {
  display: block;
  content: ''; }

.hb-inner:before {
  top: -9px; }

.hb-inner:after {
  bottom: -9px; }

.plus:before, .plus:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #0d161d; }

.plus:before {
  width: 2px;
  height: 1rem; }

.plus:after {
  width: 1rem;
  height: 2px; }

.plus.minus:before {
  content: normal; }

.fs-wapper {
  width: 3rem;
  height: 3rem; }
  .fs-wapper .icon-marker {
    width: 1rem;
    height: 1rem; }

.fs {
  vertical-align: middle;
  display: inline-block;
  border: 2px solid #0d161d;
  width: 1.75rem;
  height: 1.75rem;
  position: relative; }

.fs:before, .fs:after {
  content: '';
  background: white;
  position: absolute; }

.fs:before {
  width: 12px;
  left: calc(50% - 6px);
  top: -.5rem;
  bottom: -.5rem; }

.fs:after {
  height: 12px;
  top: calc(50% - 6px);
  left: -.5rem;
  right: -.5rem; }

@media screen and (min-width: 30em) {
  .fs-wapper {
    width: 4rem;
    height: 4rem; }
  .fs {
    width: 2rem;
    height: 2rem; } }

/*
.btn.played,
.btn.upcoming {
    padding: 5px 7px;
    text-transform: uppercase;
    span {
        font-size: 17px;
    }
    i {
        right: 0;
    }
    .arrow {
        border-color: #0FFC8A !important;
    }
}

.btn-cancel {
  color: #ff89aa;
  &::before {
      border-image: radial-gradient(farthest-corner at 0 100%, #ffb7cb 30%, #ff89aa) 1;
  }
  &::after {
      background-image: radial-gradient(farthest-corner at 0 100%, #ffb7cb 30%, #ffb7cb);
  }
}


.btn-this {
  color: #0FFC8A;
  background: #111;
  border: 1px solid #208650;
  box-shadow: inset 0px 0px 30px 0px rgba(15,252,138,0.2);
}

.btn-action {
    color: white;
    cursor: pointer;
    display: block;
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    margin: 20px auto 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    overflow: hidden;
    border-radius: 0;
    background: #111;
    border: 1px solid white;
    backface-visibility: hidden;
    text-shadow: none;
    transition: background .5s ease;
    &.disabled {
        pointer-events: none;
    }
    &.btn-primary {
      background: white;
      color: black;
      border: 0;
    }
    &.btn-secondary {
      margin-top: 12px;
      background: black;
      color: #0FFC8A;
      border-color: #0FFC8A;
    }
}

.signup .btn-action {
  margin-top: 32px;
}
a.btn-action:hover, a.btn-action:focus {
  color: white;
  text-decoration: none;
}
.setlist .btn-action {
  display: inline-block;
  margin: 4px 0 10px;
  padding: 0 8px;
  width: auto;
}
*/
.x {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem; }
  .x::before, .x::after {
    content: "";
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1.25rem;
    height: 0;
    border-top: 2px solid white;
    transform: rotate(45deg);
    transform-origin: center; }
  .x::after {
    transform: rotate(-45deg); }
  .x.x-sm {
    width: 0.55rem;
    height: 0.55rem; }
    .x.x-sm::before, .x.x-sm::after {
      width: 0.55rem;
      border-top: 1px solid white; }
  .x.x-lg {
    width: 2rem;
    height: 2rem; }
    .x.x-lg::before, .x.x-lg::after {
      width: 2rem; }
  .x.x-yellow::before, .x.x-yellow::after {
    border-color: #fcd500; }
  .x.x-black::before, .x.x-black::after {
    border-color: black; }

.sidebar .menu-toggle {
  position: absolute;
  top: 5px;
  right: -52px;
  width: 48px;
  height: 48px;
  background: #0d161d;
  border: 0; }
  .sidebar .menu-toggle.menu-toggle-close {
    background: rgba(13, 22, 29, 0.9); }

.s-button-primary .markerx.marker-icon {
  position: absolute;
  top: 6px;
  width: 30px;
  height: 30px;
  background-color: #0d161d; }
  .s-button-primary .markerx.marker-icon .number {
    border: none;
    background-color: #0d161d;
    height: 18px;
    width: 18px; }
  .s-button-primary .markerx.marker-icon:after {
    background-color: #0d161d; }
  .s-button-primary .markerx.marker-icon .number {
    background-color: #fcd500; }

.button-ticketmaster::before {
  content: 'Powered by ';
  color: #0e1921;
  font-size: 0.675rem;
  margin-top: .125rem; }

.button-ticketmaster::after {
  content: ' ';
  display: inline-block;
  width: 80px;
  height: 12px;
  margin-left: 5px;
  background: url(../assets/images/ticketmaster.png) no-repeat;
  background-size: 80px;
  background-position: center; }

.button-ticketmaster.white {
  filter: brightness(0) invert(1); }

.button-ticketmaster.button-source::before {
  content: 'Source: '; }

.button-setlistfm::before {
  content: 'Source: ';
  color: #0e1921;
  font-size: 0.75rem;
  margin-top: 5px; }

.button-setlistfm::after {
  content: ' ';
  display: inline-block;
  width: 80px;
  height: 18px;
  margin-left: 5px;
  background: url(../assets/images/setlistfm.png) no-repeat;
  background-size: 80px;
  background-position: center; }

.menu-item::after,
.menu-label::after {
  content: '';
  height: 250px;
  left: -130px;
  opacity: .3;
  position: absolute;
  top: -60px;
  transform: rotate(45deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
  background: white; }

.menu-item:not(.selected):hover::after,
.menu-label:not(.selected):hover::after {
  background: white;
  left: 120%; }

.menu-item.disabled,
.menu-label.disabled {
  pointer-events: none;
  opacity: .4;
  color: #999; }

.auth-signup .form-input {
  margin-top: 1rem; }

.auth-signup .form-input.value {
  margin-top: 2rem; }

.form-label {
  transform: scale(1) translate3d(0.5rem, 0, 0);
  transform-origin: left center;
  transition: transform .3s ease; }

.form-input.focus .form-label,
.form-input.value .form-label {
  transform: scale(0.9) translate3d(0, -100%, 0);
  font-weight: 400;
  color: #fff; }

.profile-picture input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer; }

.filters [type="checkbox"]:checked + label {
  opacity: 1; }

.menu-label::before,
.item-select::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 2px;
  transition: width 100ms cubic-bezier(0.19, 1, 0.22, 1);
  background: white; }

.menu-label:hover {
  background: rgba(255, 255, 255, 0.15); }

[type="checkbox"]:checked + .menu-label::after,
[type="radio"]:checked + .menu-label::after,
.menu-item.active::after {
  left: -115px;
  opacity: 1; }

[type="checkbox"]:checked + .menu-label::before,
[type="radio"]:checked + .menu-label::before,
.menu-item.active::before {
  transition: width 350ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%; }

.item-select > a {
  padding-top: 16px;
  padding-bottom: 16px; }

.item-select.active a {
  color: #0FFC8A; }

/*
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}
*/
.filter-label {
  font-family: 'Oswald', sans-serif;
  margin-bottom: 1px;
  opacity: 0.4; }
  .filter-label:hover {
    opacity: 1;
    background-color: rgba(44, 235, 134, 0.2); }

[type="checkbox"]:checked + .filter-label,
[type="radio"]:checked + .filter-label {
  opacity: 1; }

.event-filter-radio label {
  padding-left: 3.45rem;
  font-size: 1.15rem; }

.event-filter-radio label.active::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: 22px;
  border-radius: 50%;
  background-color: #0ffc8a;
  transform: scale(1);
  transition: all ease 0.3s; }

.event-filter-radio::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: 22px;
  border-radius: 50%;
  background-color: transparent;
  transform: scale(0); }

.event-filter-radio::before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  left: 18px;
  border-radius: 50%;
  background-color: transparent;
  border: 0.125rem solid #0ffc8a; }

.event-filter-radio.event-filter-radio-upcoming::before {
  border-color: #b0fbff; }

.event-filter-radio.event-filter-radio-upcoming label.active::after {
  background-color: #b0fbff; }

.pagination-sm .pagination-first.disabled, .pagination-sm .pagination-last.disabled {
  display: none; }

.noUi-target.slider.noUi-target {
  background: none;
  border: 0;
  box-shadow: none;
  height: 0.5rem; }
  .noUi-target.slider.noUi-target .noUi-base {
    background: #eee;
    border: none;
    height: 5px;
    border-radius: 5px; }
    .noUi-target.slider.noUi-target .noUi-base:after {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: 0;
      right: 0; }

.noUi-target.slider .noUi-connect {
  background-color: #fcd500; }

.noUi-target.slider .noUi-handle {
  height: 30px;
  width: 6px;
  top: -12px;
  left: -3px;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5); }
  .noUi-target.slider .noUi-handle:after, .noUi-target.slider .noUi-handle:before {
    content: none; }

.query-nav hr {
  height: .25rem;
  width: 50%;
  margin: 0;
  background: #fcd500;
  border: none;
  transition: 150ms; }

.query-nav li:nth-child(2).selected ~ hr {
  margin-left: 50%; }

.sidebar .query-nav hr {
  height: .15rem;
  background: #b0fbff; }

.sidebar .query-nav li:nth-child(2).selected ~ hr {
  background: #0ffc8a; }

input:focus::placeholder {
  color: transparent; }

.gig-info .venue {
  font-size: 16px;
  padding: 5px 0; }

.gig-info .tour {
  padding: 0 0 5px; }

.gig-container {
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.2s ease-in-out; }
  .gig-container.open {
    transform: translate3d(0, 0, 0); }

.gig-container h2.location {
  font-size: 19px;
  font-weight: 400; }

.gig-container .btn.close-btn {
  position: absolute;
  padding-bottom: 20px;
  right: 0;
  top: 0;
  cursor: pointer;
  color: #0FFC8A;
  font-size: 16px; }
  .gig-container .btn.close-btn span {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px; }

.gig-container .date-block {
  width: 48px; }
  .gig-container .date-block strong, .gig-container .date-block span {
    font-size: 13px; }
  .gig-container .date-block strong {
    letter-spacing: 1.6px; }
  .gig-container .date-block strong.day {
    letter-spacing: 2px;
    font-size: 23px; }

.gig-container .gig-details .venue-name {
  font-size: 16px !important; }

.gig-container .gig-details .upcoming {
  font-size: 12px !important; }

.gig-container .the-clock {
  top: 8px; }

.gig-container .gig-cluster .gig-count {
  font-size: 14px; }
  .gig-container .gig-cluster .gig-count .num {
    font-size: 18px; }

.gig-container .gig-cluster.last {
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.3), inset 0 -1px rgba(0, 0, 0, 0.3); }

/*
.gig-details {
  line-height: 1;
  .venue-name {
      margin-bottom: 1px;
      font-family: 'Oswald', sans-serif;
      font-size: 19px;
      letter-spacing: 1px;
  }
  > * {
      display: block;
  }
}
*/
.gig-header span.tour, .gig-header span.upcoming {
  letter-spacing: 1px;
  text-transform: none;
  color: #0FFC8A;
  font-size: 13px; }

.gig-header span.upcoming {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #38e9fe; }

.gig-header .gig-date {
  font-size: 16px;
  color: #0FFC8A; }

.gig-header h3.venue {
  padding-left: 22px; }
  .gig-header h3.venue::before {
    content: "\f041";
    position: absolute;
    left: 0;
    top: 3px;
    font: normal normal normal 13px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 6px; }

.gig-container-add-video .song::before,
.gig-container:not(.gig-container-fs-player) .song:not(.populated).hover::before {
  display: none; }

.gm-style iframe + div {
  border: none !important; }

#map-wrapper.setlist-active .zoom-nav {
  right: 303px; }

#map-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s; }
  #map-wrapper .zoom-nav {
    position: absolute;
    bottom: 18px;
    right: 3px;
    z-index: 100;
    transition: all 0.3s;
    overflow: hidden;
    /*
                .btn {
                  font-size:38px;
                  line-height:1;
                  padding: 0px 6px;
                  display:block;
                  color:#fff;

                  transition:background 0.3s, box-shadow 0.3s;
                  -o-transition:background 0.3s, box-shadow 0.3s;
                  -ms-transition:background 0.3s, box-shadow 0.3s;
                  -moz-transition:background 0.3s, box-shadow 0.3s;
                  -webkit-transition:background 0.3s, box-shadow 0.3s;

                  background:rgba(0,42,63,0.9);


                  &.in{

                    border-radius: 10px 10px 0 0;

                  }
                  &.out {
                    border-top:1px solid rgba(0,0,0,0.3);
                    border-radius: 0 0 10px 10px;
                  }
                  &:hover {
                    background:rgba(64,133,167,0.8);


                  }
                  &.disable {
                    color:rgba(225,225,225,0.4);
                    background:transparent;
                  }
                }
                */ }

#map-canvas {
  width: 100%;
  min-height: 100%;
  z-index: 1;
  overflow: hidden; }

#map-canvas,
.gm-style {
  background-color: #304c61; }

.markerx {
  height: 54px;
  width: 54px;
  position: absolute;
  border-radius: 50%;
  background: #0ffc8a;
  z-index: 2;
  /*&.selected {
  background: #ffae00;
}*/ }
  .markerx .number {
    content: "";
    border-radius: 50%;
    height: 44px;
    width: 44px;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    color: #0e1921; }
  .markerx:not(.marker-cluster) {
    height: 48px;
    width: 48px;
    /*
    &#marker-active {
      &::after {
      // background: white;
      }
    } */ }
    .markerx:not(.marker-cluster) .number {
      height: 38px;
      width: 38px;
      border: 3px solid #0e1921;
      font-size: 24px;
      padding-bottom: 2px; }
    .markerx:not(.marker-cluster)::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      background: #0ffc8a;
      transform: rotate(45deg);
      transform-origin: top left;
      z-index: -1; }
  .markerx.marker-cluster > .circle-spinner {
    width: 46px;
    height: 46px; }
  .markerx::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; }
  .markerx.upcoming {
    z-index: 1;
    background: #b0fbff;
    /*
      pulsating cool effect
      &::after {
          content: '';
          position: absolute;
          left: -3px;
          right: -3px;
          top: -3px;
          bottom: -3px;
          border-radius: 50% / 50%;
          border-color: #38e9fe;
          border-style: solid;
          border-width: 3px;
          pointer-events: none;
          animation: bounce-2 2s infinite;
      }*/ }
    .markerx.upcoming:not(.marker-cluster)::after {
      background: #b0fbff; }
  .markerx.focused {
    background: #fcd500; }
    .markerx.focused:not(.marker-cluster)::after {
      background: #fcd500; }
  .markerx.highlight .number, .markerx.focused .number {
    background: white; }

#marker-active .circle-spinner > span::before {
  opacity: 1; }

.markerx .circle-spinner > span::before {
  border-color: black; }

.event-popup {
  clip-path: polygon(100% 0, 100% calc(100% - 16px), calc(50% + 16px) calc(100% - 16px), 50% 100%, calc(50% - 16px) calc(100% - 16px), 0 calc(100% - 16px), 0 0);
  width: 320px;
  cursor: default;
  animation: fadeInUp 0.3s 1;
  transform-origin: center bottom;
  z-index: 4;
  overflow: hidden;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc; }
  .event-popup:before, .event-popup:after {
    content: "";
    position: absolute;
    left: 0;
    right: 6px;
    height: 32px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    pointer-events: none; }
  .event-popup hr {
    position: absolute;
    width: 100%;
    height: 16px;
    bottom: 2px;
    border: 0;
    left: 50%;
    pointer-events: none; }
    .event-popup hr:after, .event-popup hr:before {
      content: "";
      position: absolute;
      height: 18px;
      width: 100%;
      z-index: 1; }
    .event-popup hr:before {
      left: calc(-100% + 3px);
      border-top: 2px solid #ccc;
      border-right: 3px solid #ccc;
      transform-origin: left bottom;
      transform: skewX(45deg); }
    .event-popup hr:after {
      right: 3px;
      border-top: 2px solid #ccc;
      border-left: 3px solid #ccc;
      transform-origin: right bottom;
      transform: skewX(-45deg); }
  .event-popup:not(.event-popup-bottom) .event-popup-list li:last-child .event-button {
    padding-bottom: 2rem; }
    .event-popup:not(.event-popup-bottom) .event-popup-list li:last-child .event-button::after {
      margin-bottom: 1rem; }
  .event-popup.event-popup-bottom {
    clip-path: polygon(calc(50% + 16px) 16px, 100% 16px, 100% 100%, 0 100%, 0 16px, calc(50% - 16px) 16px, 50% 0);
    border: 2px solid #ccc; }
    .event-popup.event-popup-bottom.event-popup-hover-top {
      clip-path: none !important; }
      .event-popup.event-popup-bottom.event-popup-hover-top hr {
        visibility: hidden; }
    .event-popup.event-popup-bottom .event-popup-list li:first-child {
      padding-top: 16px; }
    .event-popup.event-popup-bottom hr {
      bottom: auto;
      top: -2px; }
      .event-popup.event-popup-bottom hr:before {
        left: calc(-100% + 3px);
        transform-origin: left top;
        transform: skewX(-45deg);
        border-bottom: 2px solid #ccc; }
      .event-popup.event-popup-bottom hr:after {
        transform-origin: right top;
        transform: skewX(45deg);
        border-bottom: 2px solid #ccc; }

.event-popup-hover-top:before, .event-popup-hover-top:after,
.event-popup-hover-bottom:before,
.event-popup-hover-bottom:after {
  margin-right: -5px;
  opacity: 1;
  pointer-events: all; }

.event-popup-hover-top:before,
.event-popup-hover-bottom:before {
  background-size: 12px;
  background-repeat: no-repeat;
  z-index: 2; }

.event-popup-hover-top:before, .event-popup-hover-top:after {
  bottom: auto;
  top: 0; }

.event-popup-hover-top:after {
  background: linear-gradient(0deg, rgba(14, 25, 33, 0) 0%, rgba(14, 25, 33, 0.65) 50%); }

.event-popup-hover-top:before {
  background-image: url(../assets/images/arrow-up.svg);
  background-position: center 8px; }

.event-popup-hover-bottom {
  border-bottom: 2px solid #ccc; }
  .event-popup-hover-bottom:not(.event-popup-bottom) {
    clip-path: none !important; }
    .event-popup-hover-bottom:not(.event-popup-bottom) hr {
      visibility: hidden; }
  .event-popup-hover-bottom:before, .event-popup-hover-bottom:after {
    bottom: 0;
    top: auto; }
  .event-popup-hover-bottom:after {
    background: linear-gradient(0deg, #0e1921 0%, rgba(14, 25, 33, 0) 70%); }
  .event-popup-hover-bottom:before {
    background-image: url(../assets/images/arrow-down.svg);
    background-position: center 12px; }

.event-popup-list {
  margin: 2px 2px; }

.markerx::before {
  pointer-events: none; }

.markerx.marker-pop-top::before {
  top: -100%; }

.markerx.marker-pop-bottom::before {
  bottom: -100%; }

.markerx#marker-active::before {
  pointer-events: all; }

.markerx.marker-cluster-sm {
  position: relative;
  height: 41px;
  width: 41px; }
  .markerx.marker-cluster-sm > .circle-spinner {
    width: 34px;
    height: 34px; }
    .markerx.marker-cluster-sm > .circle-spinner > span::before {
      border-width: 2px; }
  .markerx.marker-cluster-sm .number {
    height: 34px;
    width: 34px;
    font-size: 1.25rem; }

.markerx.marker-cluster-xs {
  position: relative;
  height: 32px;
  width: 32px; }
  .markerx.marker-cluster-xs > .circle-spinner {
    width: 26px;
    height: 26px; }
    .markerx.marker-cluster-xs > .circle-spinner > span::before {
      border-width: 1.5px; }
  .markerx.marker-cluster-xs .number {
    height: 26px;
    width: 26px;
    font-size: 0.875rem;
    font-weight: 400; }

.markerx.marker-sm {
  position: relative;
  margin-top: 0.15rem;
  height: 42px;
  width: 42px; }
  .markerx.marker-sm .number {
    height: 32px;
    width: 32px;
    font-size: 1.2rem;
    border-width: 3px; }

body.modal-open {
  position: fixed;
  overflow: hidden; }

.modal {
  overflow-y: scroll; }

.modal-dialog {
  margin: 0 auto;
  height: auto; }

.modal-event-open .modal-backdrop.in {
  filter: sepia(0%) saturate(-100%) hue-rotate(180deg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover; }
  .modal-event-open .modal-backdrop.in::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0e1921;
    opacity: 0.125; }

.modal-event-open .modal-dialog {
  margin: 0 auto;
  height: auto;
  width: 480px; }

.modal-event-open .modal-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%; }

.modal-open-filter .modal-dialog {
  width: 400px; }

.modal-open-center .modal {
  text-align: center;
  padding: 0 !important;
  overflow-y: auto; }

.modal-open-center .modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; }

.modal-open-center .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

/*
.modal-dialog {
  margin: 0;
  height:100%;
  width: 100%;
}
*/
.modal-content {
  background-color: #0d161d;
  border: 0;
  border-radius: 0;
  box-shadow: none; }

.modal-backdrop {
  background-color: #0d161d; }

.modal-open-cover .modal-backdrop.in {
  background-color: #0d161d;
  opacity: 0.25; }

.modal.fade .modal-dialog {
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0s ease 0s;
  -moz-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  opacity: 1; }

.modal-gendre-picker .modal-content > div {
  max-height: 100%; }

.modal-event-open #map-wrapper .markerx {
  opacity: 0; }

@media (min-width: 30rem) {
  .modal-backdrop.in {
    opacity: 0.5; }
  .modal-content {
    min-height: auto; } }

@media (max-width: 29.99999rem) {
  .modal-open-center .modal::before {
    content: none; }
  .modal-open-cover .modal::before {
    content: ''; }
  .modal-open-cover .modal-backdrop.in {
    opacity: 0.5; }
  .modal-open-center .modal-dialog {
    vertical-align: bottom; }
  .modal .modal-dialog {
    margin: 0;
    width: 100%; }
  .modal-open-fade .modal.fade .modal-dialog {
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    opacity: 1; }
  .modal-event-open .modal-backdrop.in {
    opacity: .8; }
    .modal-event-open .modal-backdrop.in::after {
      background: #0e1921;
      opacity: 1; }
  .modal-event-open .modal-dialog {
    height: 100%; }
  .modal-event-open .modal-content {
    background-color: transparent; }
  .modal-event-open .s-map-overlay {
    opacity: 0; }
  .modal-event-open .sidebar {
    opacity: 0; } }

.modal-privacy .modal-content {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem; }

/*
.home-btn {
  background-size: 30px;

    background-color: $color-yellow;
    -webkit-mask-image: url('../assets/images/home-btn.svg');
    mask-image: url('../assets/images/home-btn.svg');

    -webkit-mask-size: 30px;
    mask-size: 30px;

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}*/
.navbar-nav > li > .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100px;
  padding: 5px 0;
  margin: 10px 0 0;
  width: 100%;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .navbar-nav > li > .dropdown-menu > li > button {
    margin-bottom: 10px;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;
    font-size: 14px; }
    .navbar-nav > li > .dropdown-menu > li > button:hover {
      border-color: #208650;
      border-color: #0ffc8a; }

/** Main nav **/
.nav > li {
  position: relative;
  transition: color 0.25s; }

.nav button {
  /*border-image: radial-gradient(farthest-corner at 0 100%, #9DF6E4 30%, #0FFC8A) 1;
        border-style: solid;
        border-width: 2px;*/ }
  .nav button:not(.btn-profile):hover {
    opacity: 1; }

.nav .btn {
  padding-left: 20px;
  padding-right: 20px;
  height: 38px;
  font-size: 16px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px; }

.nav .btn-green-bold {
  background: #0FFC8A;
  color: #111;
  font-weight: 700;
  border-color: #0FFC8A; }

/** secondary nav **/
.modal-add-video .navbar-right,
.add-video .navbar-right,
.video-player .navbar-right,
.modal-settings .navbar-right {
  display: inline-block;
  right: 0; }

/** settings nav **/
.navbar-nav-sub > li {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 50px;
  text-transform: uppercase; }

.pagination-wrapper::before {
  /* content: ' '; */
  position: absolute;
  left: 0;
  right: .25rem;
  top: -20px;
  height: 20px;
  z-index: 1;
  /* -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%); */
  background-image: linear-gradient(to top, rgba(17, 26, 34, 0.9) 0%, transparent 100%); }

.pagination a {
  color: white; }

.pagination-past a {
  color: #0ffc8a; }

.pagination .disabled a {
  opacity: 0.5; }

.pagination .active a {
  opacity: 0.5; }

.date-block {
  flex: 0 0 3rem; }

.sidebar {
  top: 4.5rem;
  transform: translate3d(-100%, 0, 0); }
  .sidebar.open {
    transform: translate3d(0, 0, 0); }

@media screen and (min-width: 30em) {
  .event-browse-header {
    width: 320px; }
  .sidebar {
    width: 320px;
    transform: translate3d(0, 0, 0); } }

.event-button-icon > div {
  margin-right: 2rem; }

.event-button-icon::after {
  content: '';
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3rem; }

.event-button-icon.event-ticketed::after {
  background-size: 18px;
  background-image: url("../assets/images/icon-dialog.svg"); }

.event-button-icon.event-setlist::after {
  background-size: 25px;
  background-image: url("../assets/images/setlist-icon.png"); }

.event-button-title {
  font-size: 1.125rem; }

.event-button-title-md {
  font-size: 1.25rem; }

.event-button-title-sm {
  font-size: 1.35rem; }

/*
.event-list {
  &::after {
    content: '';
    display: block;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: none;
    background: linear-gradient(0deg, $color-dark-blue-transparent 0%, rgba(0,0,0,0) 100%);
  }
}*/
.event-list li {
  transition: margin 200ms, padding 200ms; }

.event-list li:last-child .event-button {
  padding-bottom: 1.25rem; }

.event-popup-list li:last-child {
  padding-bottom: 2rem; }

/*.event-name {
  font-size:1.175rem;
}*/
.date-block .month {
  font-size: .65rem; }

.date-block .day {
  font-size: 1.1rem;
  letter-spacing: .05em; }
  .date-block .day.eleven {
    letter-spacing: .1em; }

.date-block .year {
  margin-top: 0.125rem;
  font-size: .65rem; }

.event-name-s {
  font-size: 1.1rem; }

.event-name-l {
  font-size: 1.095rem; }

@media screen and (min-width: 30em) {
  /*.event-list {
    &::after {
      display: none;
    }
  }*/
  /*.highlight-el {
    .event-button:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }*/
  /*.event-name {
    font-size:1rem;
  }*/ }

.focused-el {
  padding-top: 2rem;
  padding-bottom: 3.5rem; }

.event-list-location li:first-of-type.focused-el {
  margin-top: 0; }

.event-list-location li:not(:first-of-type).focused-el {
  margin-top: .5rem; }

.highlight-el-last .event-button {
  padding-bottom: 1.25rem; }

.markerx.marker-single.marker-icon.marker-load {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: white; }
  .markerx.marker-single.marker-icon.marker-load::after {
    background: white; }
  .markerx.marker-single.marker-icon.marker-load .number {
    border: none;
    background-color: #0d161d;
    height: 21px;
    width: 21px; }

.map-bar-header {
  border-top-left-radius: 1rem; }

.map-nav-zoom {
  transform: translate3d(0, calc(-100% - 0.5rem), 0); }
  .map-nav-zoom > button {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5); }
    .map-nav-zoom > button:before {
      content: '';
      position: absolute;
      top: -.25rem;
      bottom: -.25rem;
      left: -1rem;
      right: -1rem; }
    .map-nav-zoom > button:nth-child(2) {
      margin-top: .75rem;
      margin-bottom: .75rem; }

/*
.s-nav-button {
  width: 2.75rem;
}

.s-nav-button > * {
  width: 1.5rem;
}

@media screen and (min-width: 30em) {
  .s-nav-button > * {
    width: 1.25rem;
  }
}*/
.s-nav-button > * {
  width: 1.75rem; }

.s-home-button {
  padding: 0.875rem;
  width: 3.25rem;
  height: 3.25rem; }

.s-map-toggle {
  width: 3.75rem;
  height: 3.125rem; }
  .s-map-toggle svg {
    width: 1.75rem;
    overflow: visible; }

.event-browse-header {
  height: 4.5rem; }

.map-sidebar-open .markerx {
  opacity: 0; }

@media screen and (min-width: 30em) {
  .map-sidebar-open .markerx {
    opacity: 1; }
  .event-browse-header {
    height: 4.25rem; }
  .sidebar {
    top: 4.25rem; } }

.s-button-standard, .s-button-secondary {
  min-width: 7rem;
  height: 2.5rem;
  font-size: 1rem; }

.s-button-secondary {
  background-color: rgba(255, 255, 255, 0.1); }

.s-search-nav > button {
  font-size: 0.8rem;
  height: 2.5rem; }

.s-filter-nav > button {
  min-width: 6rem;
  height: 2rem; }

.s-filter-nav > button:disabled,
.s-filter-nav > button[disabled] {
  opacity: 0.5; }

.s-genre-nav button {
  transition: background-color 50ms;
  background-color: rgba(255, 255, 255, 0.025); }

.s-genre-nav button.genre-checked {
  background-color: rgba(48, 79, 105, 0.2);
  border-color: #304f69; }

.s-button-listen {
  height: 1.5rem; }
  .s-button-listen > img {
    width: 0.75rem;
    height: 0.75rem; }

.s-event-action-nav {
  position: absolute;
  top: -0.5rem;
  right: 0.5rem; }
  .s-event-action-nav button {
    width: 2.5rem;
    height: 2.5rem; }
  .s-event-action-nav .s-btn-tix {
    width: 3.25rem;
    height: 3.25rem; }
    .s-event-action-nav .s-btn-tix img {
      width: 1.25rem;
      height: 1.25rem; }
  .s-event-action-nav .s-btn-directions img {
    width: 1.45rem;
    height: 1.45rem; }
  .s-event-action-nav .s-btn-popup {
    width: 3rem;
    height: 3rem; }
    .s-event-action-nav .s-btn-popup span {
      width: 1.25rem; }

.s-event-nav-btm button {
  min-width: 7rem;
  height: 2.5rem;
  font-size: .75rem;
  font-weight: bold; }

.s-event-nav-btm .s-button-tickets {
  min-width: 10rem; }

@media screen and (min-width: 30em) {
  .s-button-standard, .s-button-secondary {
    min-width: 6rem;
    height: 2rem;
    font-size: .75rem; }
  .s-filter-nav > button {
    height: 1.75rem; }
  .s-event-action-nav {
    position: absolute;
    top: -0.75rem;
    right: 0.5rem; }
    .s-event-action-nav button {
      width: 2.5rem;
      height: 2.5rem; }
      .s-event-action-nav button img {
        width: 0.875rem;
        height: 0.85rem; }
    .s-event-action-nav .s-btn-marker {
      width: 1.75rem;
      height: 1.75rem; }
      .s-event-action-nav .s-btn-marker span {
        width: 1.1rem;
        height: 1.35rem; }
  .highlight-el .event-button::before, .focused-el .event-button::before {
    content: ''; }
  .focused-el .event-button::before {
    background-color: #fcd500; } }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }
  .dropdown-menu li:first-child button {
    padding-top: 1rem; }
  .dropdown-menu button {
    padding: 0.75rem; }

.open > .dropdown-menu {
  display: block; }

.s-event-name {
  font-size: 1.125rem; }

.s-keyword-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: .7;
  /* Firefox */ }

/* Change the white to any color */
.s-keyword-input:-webkit-autofill,
.s-keyword-input:-webkit-autofill:hover,
.s-keyword-input:-webkit-autofill:focus,
.s-keyword-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.1) inset !important; }
  .s-keyword-input:-webkit-autofill + button > span > svg > path,
  .s-keyword-input:-webkit-autofill:hover + button > span > svg > path,
  .s-keyword-input:-webkit-autofill:focus + button > span > svg > path,
  .s-keyword-input:-webkit-autofill:active + button > span > svg > path {
    fill: black; }

.s-link-popup > span {
  width: .75rem;
  height: .75rem; }

.sidebar-events-container:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  z-index: -1;
  /* -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%); */
  background-image: linear-gradient(to top, rgba(17, 26, 34, 0.9) 0%, transparent 100%); }

@media screen and (min-width: 30em) {
  .sidebar-events-container:after {
    content: none; } }

.s-query-title {
  margin-top: .15rem; }
  .s-query-title .s-icon-marker {
    width: .7rem; }

@media screen and (min-width: 30em) {
  .s-query-title .s-icon-marker {
    margin-right: 0.4rem; } }

#preloader .loader-wrapper {
  width: 280px;
  height: 280px; }
  #preloader .loader-wrapper .loaderx {
    animation-duration: 1500ms; }
    #preloader .loader-wrapper .loaderx > span::before {
      border-width: 5px; }
    #preloader .loader-wrapper .loaderx > span:first-of-type::before {
      border-color: #1bf9a8; }

#preloader .logo-container {
  width: 230px; }

.scrollbar {
  overflow-y: auto; }

.scroll-absolute {
  position: absolute;
  top: 6px;
  bottom: 6px;
  right: 6px;
  left: 0; }

.scrollbar::-webkit-scrollbar-track {
  background-color: transparent; }

.scrollbar::-webkit-scrollbar-track-piece:start {
  margin-top: 0.25rem; }

.scrollbar::-webkit-scrollbar-track-piece:end {
  margin-bottom: 0.25rem; }

.scrollbar::-webkit-scrollbar {
  position: absolute;
  width: .33rem; }

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #475461;
  border-radius: .33rem;
  outline: 1px solid rgba(0, 0, 0, 0.5); }

.event-popup .scrollbar::-webkit-scrollbar-thumb {
  background-color: #eee; }

.event-popup .scrollbar::-webkit-scrollbar-track-piece:start {
  background-color: transparent;
  margin-top: 0; }

.event-popup .scrollbar::-webkit-scrollbar-track-piece:end {
  background-color: transparent;
  margin-bottom: 18px; }

.event-popup.event-popup-bottom .scrollbar::-webkit-scrollbar-track-piece:start {
  margin-top: 18px; }

.event-popup.event-popup-bottom .scrollbar::-webkit-scrollbar-track-piece:end {
  margin-bottom: 0; }

.s-genre-picker .scrollbar::-webkit-scrollbar {
  position: absolute;
  width: 0.4rem; }

.s-genre-picker .scrollbar::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 0.2rem; }

.s-genre-picker .scrollbar::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 0; }

.page-body {
  margin-top: 30px; }

.backdrop {
  background: rgba(0, 0, 0, 0.1);
  z-index: 1; }
  .backdrop.backdrop-home {
    background: rgba(0, 0, 0, 0.6);
    z-index: 1051; }

.social-auth {
  /*
      .btn-lock-social {
          border: 0;
          border-radius: 0;
          display: block;
          transition: background .3s;
      }
      .btn-lock-social-icon {
          line-height: 2.5rem;
      }
      .btn-lock-social-text {
          box-sizing: border-box;
          //padding: 5px 15px 5px auto;
          line-height: 30px;
          text-align: left;
          text-transform: uppercase;
          color: #fff;
      }
      */ }
  .social-auth .btn-lock-social[data-provider^=facebook] {
    background-color: #3b5998; }
  .social-auth .btn-lock-social[data-provider^=google] {
    background-color: #dd4b39; }
  .social-auth .btn-lock-social[data-provider^=twitter] {
    background-color: #55acee; }

.copy-separate {
  position: relative;
  margin: 15px 0; }
  .copy-separate:before {
    height: 1px;
    background-color: #208650;
    margin: 10px 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%; }
  .copy-separate p {
    background-color: #121212;
    margin-top: -25px;
    display: table;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 0 10px; }

.loader {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid #fcd500;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear; }

.circle-spinner.loaderx {
  width: 50px;
  height: 50px; }
  .circle-spinner.loaderx > span::before {
    border-color: white;
    border-width: 4px; }
  .circle-spinner.loaderx.circle-spinner-small {
    width: 30px;
    height: 30px; }
    .circle-spinner.loaderx.circle-spinner-small > span::before {
      border-width: 3px; }

.spinner {
  width: 300px;
  height: 300px;
  border-top: 1px solid #0FFC8A;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: spin 1s infinite linear; }

.spin {
  animation: spin 1.5s infinite linear; }

.circle-spinner > span {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 50%;
  left: 0;
  transform-origin: 100% 100%; }
  .circle-spinner > span:nth-child(1) {
    transform: rotate(-45deg) skewX(0deg); }
  .circle-spinner > span:nth-child(2) {
    transform: rotate(135deg) skewX(0deg); }
  .circle-spinner > span::before {
    content: '';
    opacity: 1;
    box-sizing: border-box;
    display: block;
    border: solid 3px #0FFC8A;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    transform: skewX(0deg); }

.circle-spinner {
  border-radius: 50% / 50%;
  animation-name: spin;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.circle-spinner.loaderx {
  animation-duration: 1000ms; }
  .circle-spinner.loaderx.loader-blue > span::before {
    border-color: #b0fbff; }
  .circle-spinner.loaderx.loader-green > span::before {
    border-color: #0ffc8a; }
  .circle-spinner.loaderx.loader-yellow > span::before {
    border-color: #fcd500; }
  .circle-spinner.loaderx > span::before {
    opacity: 1; }

.strength-meter {
  height: 3px; }

.strength-meter::before,
.strength-meter::after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #121212;
  border-style: solid;
  border-width: 0 4px 0 4px;
  position: absolute;
  width: 20%;
  width: calc(20% + 5px);
  z-index: 10; }

.strength-meter::before {
  left: 20%;
  left: calc(20% - 3px); }

.strength-meter::after {
  right: 20%;
  right: calc(20% - 3px); }

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s; }

.strength-meter-fill[data-strength='0'] {
  background: #ff4141;
  width: 20%; }

.strength-meter-fill[data-strength='1'] {
  background: orangered;
  width: 40%; }

.strength-meter-fill[data-strength='2'] {
  background: orange;
  width: 60%; }

.strength-meter-fill[data-strength='3'] {
  background: yellowgreen;
  width: 80%; }

.strength-meter-fill[data-strength='4'] {
  background: #0ffc8a;
  width: 100%; }

.setlist .menu-item::after {
  content: normal; }

/*
.menu-item {
  position: relative;
  font-size: 14px;
  border-top: 1px solid transparent;
  overflow: hidden;
  cursor: pointer;
  .track-content, > a, > .gig-header {
      position: relative;
      display: block;
      padding: 10px 15px 10px 50px;
      transition: all 0.25s ease;
      div, > span {
          display: block;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          letter-spacing: 2px;
          font-weight: 400;
          margin-bottom: 0;
      }
      small {
          font-weight: 300;
      }
      .track-connect {
        opacity: 0;
        z-index: -1;
      }
      &.update .track-connect {
        opacity: 1;
      }
  }
  .track-content {
      padding: 6px 40px 6px 50px;
  }
  &.disabled .track-content {
      &:hover, &:focus, &.update {
          background: none;
      }
  }
  .end-time {
      cursor: default;
      pointer-events: none;
  }
  .gig-header::before,
  .gig-header::after {
      content: normal;
      border: 0;
  }
  &.set-encore,
  &.set-name {
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-left: 12px;
  }
  &.song {
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      counter-increment: song-counter;
      .track-content {
          color: white;
          opacity: 1;
      }
      &:hover {
          .track-content:not(.active) {
              color: #0FFC8A;
              opacity: 1;
          }
      }
      &::before {
          content: counter(song-counter) '.';
          display: inline-block;
          position: absolute;
          padding-top: 13px;
          top: 0;
          bottom: 0;
          width: 34px;
          font-size: 13px;
          opacity: 0.8;
          text-align: right;
      }
      &.disabled {
          .track-content {
              opacity: .6;
              color: white;
          }
          a {
              pointer-events: all;
          }
      }
  }
}

.menu-item > a {
  padding-left: 15px;
  text-decoration: none;
  color: white;
  &:hover {
      color: #0FFC8A;
  }
}

.menu-item.includes {
  .track-content {
      color: #53a0fd !important;
      &::before, &::after {
          background: rgb(248, 118, 100);
          background: -moz-linear-gradient(#a507ff 0%, #53a0fd 50%, #00ffc0 100%);
          background: -webkit-linear-gradient(#a507ff 0%, #53a0fd 50%, #00ffc0 100%);
          background: linear-gradient(#a507ff 0%, #53a0fd 50%, #00ffc0 100%);
      }
  }
}
*/
.progress-top-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1051; }

.progress-bar {
  width: 0px;
  height: 3px;
  background-image: radial-gradient(farthest-corner at 0 100%, #9DF6E4 30%, #0FFC8A);
  transition: width 250ms cubic-bezier(0.19, 1, 0.22, 1); }

.progress-top-wrapper .progress-bar {
  height: 4px; }

.progress-password-wrapper {
  margin-top: 6px;
  padding-bottom: 20px; }
  .progress-password-wrapper .progress {
    background: #e3e3e3; }
  .progress-password-wrapper .progress-bar > span {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    font-weight: 300; }

.reset .progress-bar {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  width: 0px; }

progress {
  border: none;
  width: 100%;
  height: 0.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none; }

progress::-moz-progress-bar {
  background: #0d161d; }

progress::-webkit-progress-value {
  transition: width 500ms cubic-bezier(0, 0.72, 0.2, 0.98);
  background: #fcd500; }

progress.reset::-webkit-progress-value {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  width: 0px; }

progress::-webkit-progress-bar {
  background: transparent; }

.carousel-next:hover, .carousel-prev:hover {
  color: #b0fbff; }

.carousel-next.disabled, .carousel-prev.disabled {
  color: rgba(255, 255, 255, 0.15); }

.carousel-current-playing:after {
  content: '';
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.65);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.carousel-selected {
  border-width: 2px; }
  .carousel-selected:hover {
    border-width: 2px;
    border-color: #0ffc8a; }

.moveToLeft {
  animation: slideLeft 1s !important; }

.moveToRight {
  animation-name: slideRight 1s !important; }

setlist .song {
  counter-increment: song-counter; }
  setlist .song::before {
    content: counter(song-counter) ".";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.5rem;
    width: 1.5rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
    height: 1rem;
    text-align: right;
    color: #aaa; }

setlist ol:first-of-type {
  counter-reset: song-counter; }

.header-container {
  width: 263px; }

.s-input-primary {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.logo {
  width: 263px;
  height: 80px; }
  .logo path {
    fill: #fcd500; }
    .logo path.a {
      -webkit-animation: pivot 5s cubic-bezier(0, 1.19, 0.14, 0.98) infinite;
      -moz-animation: pivot 5s cubic-bezier(0, 1.19, 0.14, 0.98) infinite;
      animation: pivot 5s cubic-bezier(0, 1.19, 0.14, 0.98) infinite;
      transform-origin: center;
      transform-box: fill-box; }

.checkbox {
  display: block;
  width: 42px;
  height: 24px;
  cursor: pointer;
  position: relative; }

.genre-checked .checkbox .toggler-slider {
  background-color: #fcd500; }

.checkbox .toggler-slider {
  background-color: #ccc;
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease; }

.checkbox .toggler-knob {
  position: absolute;
  -webkit-transition: all 100ms;
  transition: all 100ms; }

.genre-checked .checkbox .toggler-slider .toggler-knob {
  left: 20px; }

.checkbox .toggler-knob {
  width: 20px;
  height: 20px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgba(13, 22, 29, 0.5); }

@media screen and (min-width: 30em) {
  .checkbox {
    width: 26px;
    height: 16px; }
  .genre-checked .checkbox .toggler-slider .toggler-knob {
    left: 11px; }
  .checkbox .toggler-knob {
    width: 14px;
    height: 14px;
    top: 1px; } }

.wrapper {
  padding: 0;
  display: flex;
  justify-content: center;
  background: #0d161d; }

.wheel {
  color: #fff;
  display: block;
  height: 200px;
  overflow: visible;
  width: 100%; }

.wheel--perspective-right .wheel__inner {
  perspective-origin: calc(50% + 100px) 50%;
  transform: translateX(10px);
  -webkit-transform: translateX(10px); }

.wheel--perspective-left .wheel__inner {
  perspective-origin: calc(50% - 100px) 50%;
  transform: translateX(-10px);
  -webkit-transform: translateX(-10px); }

.wheel__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  transform-style: preserve-3d;
  height: 16%;
  width: 100%; }

.wheel__slides {
  height: 100%;
  position: relative;
  width: 100%; }

.wheel__shadow-top,
.wheel__shadow-bottom {
  background: linear-gradient(to bottom, rgba(13, 22, 29, 0.9) 0%, rgba(13, 22, 29, 0.5) 100%);
  left: 0;
  height: calc(42% + 2px);
  width: 100%;
  border-bottom: 0.5px solid #555;
  position: relative;
  margin-top: -2px;
  z-index: 5;
  transform: translateZ(100px);
  -webkit-transform: translateZ(100px); }

.wheel__shadow-bottom {
  background: linear-gradient(to bottom, rgba(13, 22, 29, 0.5) 0%, rgba(13, 22, 29, 0.9) 100%);
  margin-top: 2px;
  border-bottom: none;
  border-top: 0.5px solid #555; }

.wheel__label {
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  margin-top: 1px;
  margin-left: 5px;
  transform: translateZ(100px);
  -webkit-transform: translateZ(100px); }

.wheel__slide {
  align-items: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: flex;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center; }

@-moz-keyframes pivot {
  0% {
    -moz-transform: rotate(0deg); }
  50% {
    -moz-transform: rotate(45deg); }
  100% {
    -moz-transform: rotate(0deg); } }

@-webkit-keyframes pivot {
  0% {
    -webkit-transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(45deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

@keyframes pivot {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(45deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes pulsate {
  0% {
    transform: scale(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0); }
  to {
    opacity: 1;
    transform: none; } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*.modal-section-header {
  background: rgb(13,22,29);
  background: linear-gradient(0deg, rgba(13,22,29,0.75) 25%, rgba(13,22,29,0) 100%);
}
*/
.s-event-nav-btm:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 76px;
  background: #0d161d;
  background: linear-gradient(0deg, #0d161d 0%, rgba(13, 22, 29, 0) 100%);
  z-index: 1; }

.icon-marker-sm {
  width: 1.25rem;
  height: 1.4rem;
  overflow: visible; }

.icon-check {
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid #fcd500;
  border-right: 2px solid #fcd500; }

.arrow {
  background: white;
  height: 1px;
  width: 10px;
  position: relative;
  cursor: pointer; }
  .arrow:before, .arrow:after {
    content: "";
    background: white;
    position: absolute;
    height: 1px;
    width: 6px; }
  .arrow:before {
    right: -1px;
    bottom: -2px;
    transform: rotate(-45deg); }
  .arrow:after {
    right: -1px;
    top: -2px;
    transform: rotate(45deg); }

.caret {
  border: solid white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px; }

.loading-dots:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite; }

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.3em 0 0 rgba(0, 0, 0, 0), 0.6em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: white;
    text-shadow: 0.3em 0 0 rgba(0, 0, 0, 0), 0.6em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.3em 0 0 white, 0.6em 0 0 rgba(0, 0, 0, 0); }
  80%, 100% {
    text-shadow: .3em 0 0 white, .6em 0 0 white; } }

/*
      .icon-marker-sm > svg {
        margin-top: -2px;
        margin-left: -1px;
        height: calc(100% + 1px);
      }*/
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: #0d161d;
  color: white;
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: white;
  cursor: pointer; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.logo-small-wrapper svg {
  fill: #fcd500;
  width: 100px;
  height: 30px; }

.cluster {
  display: none; }

.glyphicon-ok:before {
  content: "\e013"; }
