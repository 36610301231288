@import 'variables';

.strength-meter {
  height: 3px;
}

.strength-meter::before,
.strength-meter::after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: rgb(18, 18, 18);
  border-style: solid;
  border-width: 0 4px 0 4px;
  position: absolute;
  width: 20%;
  width: calc(20% + 5px);
  z-index: 10;
}

.strength-meter::before {
  left: 20%;
  left: calc(20% - 3px);
}

.strength-meter::after {
  right: 20%;
  right: calc(20% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
  background: $color-red;
  width: 20%;
}

.strength-meter-fill[data-strength='1'] {
  background: orangered;
  width: 40%;
}

.strength-meter-fill[data-strength='2'] {
  background: orange;
  width: 60%;
}

.strength-meter-fill[data-strength='3'] {
  background: yellowgreen;
  width: 80%;
}

.strength-meter-fill[data-strength='4'] {
  background: $color-green;
  width: 100%;
}
