/*.modal-section-header {
  background: rgb(13,22,29);
  background: linear-gradient(0deg, rgba(13,22,29,0.75) 25%, rgba(13,22,29,0) 100%);
}
*/
.s-event-nav-btm:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height:76px;
  background: rgb(13,22,29);
  background: linear-gradient(0deg, rgba(13,22,29,1) 0%, rgba(13,22,29,0) 100%);
  z-index: 1;
}