.icon-marker-sm {
  width: 1.25rem;
  height: 1.4rem;
  overflow: visible;
}

.icon-check {
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid $color-yellow;
  border-right: 2px solid $color-yellow;
}

.arrow {
  background: white;
  height: 1px;
  width: 10px;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    background: white;
    position: absolute;
    height: 1px;
    width: 6px;
  }

  &:before {
    right: -1px;
    bottom: -2px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -1px;
    top: -2px;
    transform: rotate(45deg);
  }
}

.caret {
  border: solid white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}

.loading-dots:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .3em 0 0 rgba(0,0,0,0),
      .6em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .3em 0 0 rgba(0,0,0,0),
      .6em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .3em 0 0 white,
      .6em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .3em 0 0 white,
      .6em 0 0 white;}}

/*
      .icon-marker-sm > svg {
        margin-top: -2px;
        margin-left: -1px;
        height: calc(100% + 1px);
      }*/