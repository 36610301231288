//@import url('https://fonts.googleapis.com/css?family=Oswald:300,400&text=1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz%20');
@import './styles/variables';
@import './styles/media_queries';
@import './styles/resets';
@import './styles/tachyon_extensions';

@import './styles/buttons';
@import './styles/dropdown';
@import './styles/forms';
@import './styles/gig';
@import './styles/map';
@import './styles/modals';
@import './styles/nav';
@import './styles/preloader';
@import './styles/scroll_bar';
@import './styles/sidebar';
@import './styles/social';
@import './styles/spinners';
@import './styles/strength_meter';
// @import './styles/video';
@import './styles/menu';
@import './styles/progressbar';
@import './styles/carousel';
@import './styles/setlist';
@import './styles/home';
@import './styles/animations';
@import './styles/event';
@import './styles/icons';
body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background: $color-darkest-blue;
    color: white;
    overscroll-behavior-y: contain;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: white;
    cursor: pointer;
}

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.logo-small-wrapper {
  svg {
    fill: $color-yellow;
    width: 100px;
    height: 30px;
  }
}
.cluster {
    display: none;
}



.glyphicon-ok:before {
  content: "\e013";
}
