@import 'variables';

.hb-inner {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  display: block;
}
.hb-inner, .hb-inner:after, .hb-inner:before {
  position: absolute;
  width: 28px;
  height: 2px;
  background-color: white;
}
.hb-inner:after, .hb-inner:before {
  display: block;
  content: '';
}
.hb-inner:before{
  top: -9px
}
.hb-inner:after{
  bottom: -9px
}

.plus {
  &:before, &:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: $color-darkest-blue;
  }
  &:before{
    width: 2px;
    height: 1rem;
  }
  &:after{
    width: 1rem;
    height: 2px;
  }
  &.minus{
    &:before{
      content: normal;
    }
  }
}

.fs-wapper {
  width:3rem;
  height:3rem;
  .icon-marker {
    width: 1rem;
    height: 1rem;
  }
}
.fs {
  vertical-align: middle;
  display: inline-block;
  border: 2px solid $color-darkest-blue;
  width: 1.75rem;
  height: 1.75rem;
  position: relative;
}
.fs:before, .fs:after {
  content: '';
  background: white;
  position: absolute;
}
.fs:before {
   width: 12px;
    left: calc(50% - 6px);
    top: -.5rem;
    bottom: -.5rem;

}
.fs:after {
  height: 12px;
  top: calc(50% - 6px);
  left: -.5rem;
  right: -.5rem;
}

@media screen and (min-width: 30em) {
  .fs-wapper {
    width:4rem;
    height:4rem;
  }
  .fs {
   width:2rem;
   height:2rem;
  }
}

/*
.btn.played,
.btn.upcoming {
    padding: 5px 7px;
    text-transform: uppercase;
    span {
        font-size: 17px;
    }
    i {
        right: 0;
    }
    .arrow {
        border-color: #0FFC8A !important;
    }
}

.btn-cancel {
  color: #ff89aa;
  &::before {
      border-image: radial-gradient(farthest-corner at 0 100%, #ffb7cb 30%, #ff89aa) 1;
  }
  &::after {
      background-image: radial-gradient(farthest-corner at 0 100%, #ffb7cb 30%, #ffb7cb);
  }
}


.btn-this {
  color: #0FFC8A;
  background: #111;
  border: 1px solid #208650;
  box-shadow: inset 0px 0px 30px 0px rgba(15,252,138,0.2);
}

.btn-action {
    color: white;
    cursor: pointer;
    display: block;
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    margin: 20px auto 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    overflow: hidden;
    border-radius: 0;
    background: #111;
    border: 1px solid white;
    backface-visibility: hidden;
    text-shadow: none;
    transition: background .5s ease;
    &.disabled {
        pointer-events: none;
    }
    &.btn-primary {
      background: white;
      color: black;
      border: 0;
    }
    &.btn-secondary {
      margin-top: 12px;
      background: black;
      color: #0FFC8A;
      border-color: #0FFC8A;
    }
}

.signup .btn-action {
  margin-top: 32px;
}
a.btn-action:hover, a.btn-action:focus {
  color: white;
  text-decoration: none;
}
.setlist .btn-action {
  display: inline-block;
  margin: 4px 0 10px;
  padding: 0 8px;
  width: auto;
}
*/

.x {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;

  &::before, &::after {
    content: "";
    position: absolute;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1.25rem;
    height: 0;
    border-top: 2px solid white;
    transform: rotate(45deg);
    transform-origin: center;
  }
  &::after {
    transform: rotate(-45deg);
  }

  &.x-sm {
    width: 0.55rem;
    height: 0.55rem;
    &::before, &::after {
      width: 0.55rem;
      border-top: 1px solid white;
    }
  }

  &.x-lg {
    width: 2rem;
    height: 2rem;
    &::before, &::after {
      width: 2rem;
    }
  }

  &.x-yellow {
    &::before, &::after {
      border-color: $color-yellow;
    }
  }

  &.x-black {
    &::before, &::after {
      border-color: black;
    }
  }
}

.sidebar {
  .menu-toggle  {
    position: absolute;
    top: 5px;
    right: -52px;
    width: 48px;
    height: 48px;
    background: $color-darkest-blue;
    border: 0;

    &.menu-toggle-close {
      background: $color-darkest-blue-transparent;
    }
  }
}

.s-button-primary {
  .markerx.marker-icon {
    position: absolute;
    top: 6px;
    width: 30px;
    height: 30px;
    background-color: $color-darkest-blue;
    .number {
      border:none;
      background-color: $color-darkest-blue;
      height: 18px;
      width: 18px;
    }

    &:after {
      background-color: $color-darkest-blue;
    }
    .number {
      background-color: $color-yellow;
    }
  }
}


.button-ticketmaster  {
  &::before {
    content: 'Powered by ';
    color:$color-dark-blue;
    font-size: 0.675rem;
    margin-top: .125rem;
  }
  &::after {
    content: ' ';
    display: inline-block;
    width: 80px;
    height: 12px;
    margin-left: 5px;
    background: url(../assets/images/ticketmaster.png) no-repeat;
    background-size: 80px;
    background-position: center;
  }

  &.white {
    filter: brightness(0) invert(1);
  }

  &.button-source::before {
    content: 'Source: ';
  }
}

.button-setlistfm  {
  &::before {
    content: 'Source: ';
    color:$color-dark-blue;
    font-size: 0.75rem;
    margin-top: 5px;
  }
  &::after {
    content: ' ';
    display: inline-block;
    width: 80px;
    height: 18px;
    margin-left: 5px;
    background: url(../assets/images/setlistfm.png) no-repeat;
    background-size: 80px;
    background-position: center;
  }
}
