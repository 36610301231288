
setlist {
  .song {
    counter-increment: song-counter;
      &::before {
        content: counter(song-counter) '.';
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -0.5rem;
        width: 1.5rem;
        font-size: 0.75rem;
        line-height: 1.5rem;
        height: 1rem;
        text-align: right;
        color: #aaa;
      }
    }
  
    ol:first-of-type {
      counter-reset: song-counter;
    }
}

