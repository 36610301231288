
html,
body {
    margin: 0;
    padding: 0;
}

menu,
ol,
ul {
    list-style: none;
}
hr,
html,
i,
img,
label,
li,
menu,
ol,
p,
small,
span,
strong,
sub,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
ul {
    font-size: 100%;
};



fieldset {
    border: 0;
}

*:focus {
    outline: 0 !important;
}

* {
  margin: 0;
  padding: 0;
}

/** main nav **/
.modal-add-video .navbar-map,
.add-video .navbar-map,
.video-player .navbar-map,
.modal-hidesidebar .navbar-map {
    display: none;
}

button {
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  appearance: none;
}
