.gig-info {
  .venue {
      font-size: 16px;
      padding: 5px 0;
  }
  .tour {
      padding: 0 0 5px;
  }
}

.gig-container {
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.2s ease-in-out;
  &.open {
    transform: translate3d(0, 0, 0);
  }
}

.gig-container {
  h2.location {
      font-size: 19px;
      font-weight: 400;
  }
  .btn.close-btn {
      position: absolute;
      padding-bottom: 20px;
      right: 0;
      top: 0;
      cursor: pointer;
      color: #0FFC8A;
      font-size: 16px;
      span {
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 2px;
      }
  }
  .date-block {
      width: 48px;
      strong, span {
          font-size: 13px;
      }
      strong {
          letter-spacing: 1.6px;
      }
      strong.day {
          letter-spacing: 2px;
          font-size: 23px;
      }
  }
  .gig-details {
      .venue-name {
          font-size: 16px !important;
      }
      .upcoming {
          font-size: 12px !important;
      }
  }
  .the-clock {
      top: 8px;
  }
  .gig-cluster {
      .gig-count {
          font-size: 14px;
          .num {
              font-size: 18px;
          }
      }
      &.last {
          box-shadow: inset 0 1px rgba(0, 0, 0, 0.3), inset 0 -1px rgba(0, 0, 0, 0.3);
      }
  }
}


/*
.gig-details {
  line-height: 1;
  .venue-name {
      margin-bottom: 1px;
      font-family: 'Oswald', sans-serif;
      font-size: 19px;
      letter-spacing: 1px;
  }
  > * {
      display: block;
  }
}
*/
.gig-header {
  span.tour, span.upcoming {
      letter-spacing: 1px;
      text-transform: none;
      color: #0FFC8A;
      font-size: 13px;
  }
  span.upcoming {
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #38e9fe;
  }
 .gig-date {
      font-size: 16px;
      color: #0FFC8A;
  }
  h3.venue {
      padding-left: 22px;
      &::before {
        content: "\f041";
          position: absolute;
          left: 0;
          top: 3px;
          font: normal normal normal 13px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 6px;
      }
  }
}

.gig-container-add-video .song::before,
.gig-container:not(.gig-container-fs-player) .song:not(.populated).hover::before {
  display: none;
}
