.scrollbar {
    overflow-y: auto;
}
.scroll-absolute {
    position: absolute;
    top: 6px;
    bottom: 6px;
    right: 6px;
    left: 0;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-track-piece:start {
  margin-top: 0.25rem;
}
.scrollbar::-webkit-scrollbar-track-piece:end {
  margin-bottom: 0.25rem;
}
.scrollbar::-webkit-scrollbar {
  position:absolute;
  width: .33rem;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #475461;
  border-radius:.33rem;
  outline: 1px solid rgba(0,0,0,0.5);
}
.event-popup {
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
  .scrollbar::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
    margin-top: 0;
  }
  .scrollbar::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: 18px;
  }
  &.event-popup-bottom {
    .scrollbar::-webkit-scrollbar-track-piece:start {
      margin-top: 18px;
    }
    .scrollbar::-webkit-scrollbar-track-piece:end {
      margin-bottom: 0;
    }
  }
}

.s-genre-picker {
  .scrollbar::-webkit-scrollbar {
    position:absolute;
    width: 0.4rem;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 0.2rem;
  }
  .scrollbar::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 0;
  }
}

.page-body {
    margin-top: 30px;
}
.backdrop {
    background: rgba(0, 0, 0, .1);
    z-index: 1;
    &.backdrop-home {
      background: rgba(0, 0, 0, .6);
      z-index: 1051;
    }
}
