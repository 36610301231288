@media (min-width: 768px) {
  #coming-soon {
      display: none;
  }
}

@media(max-width:767px) {
  .nopadding {
      padding: 0 !important;
  }
}


@media (min-height: 630px) and (min-width: 1314px) {
  .player-add-video {
    #video-element {
      width:calc(#{$player-width-medium} + 300px);
    }
    .youtube-player-wrapper {
      width: $player-width-medium;
    }
  }
  .add-video-selected-tracks {
    margin-top: calc(#{$player-height-medium} + 3rem);
  }
}

@media (min-height: 854px) and (min-width: 1740px) {
  .player-add-video {
    #video-element {
      width:calc(#{$player-width-large} + 300px);
      margin:0 auto;
    }
    .youtube-player-wrapper {
      width: $player-width-large;
    }
  }
  .add-video-selected-tracks {
    margin-top: calc(#{$player-height-large} + 3rem);
  }
}

/* Minimum aspect ratio */
@media (min-aspect-ratio: 16/8) {
  #video-element {
    width:80%;
  }
}
@media (min-aspect-ratio: 15/8) {
  #video-element {
    width:84%;
  }
}
