#preloader {
  .loader-wrapper {
      width: 280px;
      height: 280px;
      .loaderx {
          animation-duration: 1500ms;
          > span {
              &::before {
                  border-width: 5px;
              }
              &:first-of-type::before {
                  border-color: #1bf9a8;
              }
          }
      }
  }
  .logo-container {
      width: 230px;
  }
}