$color-darker-gray: #151b26;

$color-darkest-gray: #050b16;

$color-green: #0ffc8a;

$color-blue: #b0fbff;

$color-red: #ff4141;

$color-yellow: #fcd500;

$color-dark-blue: #0e1921;

$color-darkest-blue: #0d161d;

$color-mid-blue: #304f69;

$color-pickled-blue: #304c61;

$color-mid-blue-transparent: rgba(48, 79, 105, 0.75);

$color-darkest-blue-transparent: rgba(13, 22, 29, 0.9);

$color-dark-blue-transparent: rgba(17, 26, 34, 0.9);

$color-white-015: rgba(255, 255, 255, 0.15);

$player-width-small: 640px;

$player-width-medium: 854px;

$player-width-large: 1280px;

$player-height-small: 360px;

$player-height-medium: 480px;

$player-height-large: 720px;
