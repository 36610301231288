
.setlist .menu-item::after {
    content: normal;
}

/*
.menu-item {
  position: relative;
  font-size: 14px;
  border-top: 1px solid transparent;
  overflow: hidden;
  cursor: pointer;
  .track-content, > a, > .gig-header {
      position: relative;
      display: block;
      padding: 10px 15px 10px 50px;
      transition: all 0.25s ease;
      div, > span {
          display: block;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          letter-spacing: 2px;
          font-weight: 400;
          margin-bottom: 0;
      }
      small {
          font-weight: 300;
      }
      .track-connect {
        opacity: 0;
        z-index: -1;
      }
      &.update .track-connect {
        opacity: 1;
      }
  }
  .track-content {
      padding: 6px 40px 6px 50px;
  }
  &.disabled .track-content {
      &:hover, &:focus, &.update {
          background: none;
      }
  }
  .end-time {
      cursor: default;
      pointer-events: none;
  }
  .gig-header::before,
  .gig-header::after {
      content: normal;
      border: 0;
  }
  &.set-encore,
  &.set-name {
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-left: 12px;
  }
  &.song {
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      counter-increment: song-counter;
      .track-content {
          color: white;
          opacity: 1;
      }
      &:hover {
          .track-content:not(.active) {
              color: #0FFC8A;
              opacity: 1;
          }
      }
      &::before {
          content: counter(song-counter) '.';
          display: inline-block;
          position: absolute;
          padding-top: 13px;
          top: 0;
          bottom: 0;
          width: 34px;
          font-size: 13px;
          opacity: 0.8;
          text-align: right;
      }
      &.disabled {
          .track-content {
              opacity: .6;
              color: white;
          }
          a {
              pointer-events: all;
          }
      }
  }
}

.menu-item > a {
  padding-left: 15px;
  text-decoration: none;
  color: white;
  &:hover {
      color: #0FFC8A;
  }
}

.menu-item.includes {
  .track-content {
      color: #53a0fd !important;
      &::before, &::after {
          background: rgb(248, 118, 100);
          background: -moz-linear-gradient(#a507ff 0%, #53a0fd 50%, #00ffc0 100%);
          background: -webkit-linear-gradient(#a507ff 0%, #53a0fd 50%, #00ffc0 100%);
          background: linear-gradient(#a507ff 0%, #53a0fd 50%, #00ffc0 100%);
      }
  }
}
*/
