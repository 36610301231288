.header-container {
  width: 263px;
}

.s-input-primary {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.logo {
  width: 263px;
  height: 80px;

  path {
    fill: $color-yellow;
    &.a {
      -webkit-animation: pivot 5s cubic-bezier(0,1.19,.14,.98) infinite;
      -moz-animation: pivot 5s cubic-bezier(0,1.19,.14,.98) infinite;
      animation: pivot 5s cubic-bezier(0,1.19,.14,.98) infinite;
      transform-origin: center;
      transform-box: fill-box;
    }
  }
}

.checkbox {
  display: block;
  width: 42px;
  height: 24px;
  cursor: pointer;
  position: relative;
}

.genre-checked .checkbox .toggler-slider {
  background-color: $color-yellow;
}

.checkbox .toggler-slider {
  background-color: #ccc;
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}

.checkbox .toggler-knob {
  position: absolute;
  -webkit-transition: all 100ms;
  transition: all 100ms;
}

.genre-checked .checkbox .toggler-slider .toggler-knob {
  left: 20px;
}

.checkbox .toggler-knob {
  width: 20px;
  height: 20px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background-color:white;
  border:1px solid rgba(13, 22, 29, 0.5);
}

@media screen and (min-width: 30em) {
  .checkbox {
    width: 26px;
    height: 16px;
  }

  .genre-checked .checkbox .toggler-slider .toggler-knob {
    left: 11px;
  }

  .checkbox .toggler-knob {
    width: 14px;
    height: 14px;
    top: 1px;
  }
}
.wrapper {
  padding: 0;
  display: flex;
  justify-content: center;
  background: $color-darkest-blue;
}

.wheel {
  color: #fff;
  display: block;
  height: 200px;
  overflow: visible;
  width: 100%;
}
.wheel--perspective-right .wheel__inner {
  perspective-origin: calc(50% + 100px) 50%;
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
}
.wheel--perspective-left .wheel__inner {
  perspective-origin: calc(50% - 100px) 50%;
  transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
}

.wheel__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  transform-style: preserve-3d;
  height: 16%;
  width: 100%;
}

.wheel__slides {
  height: 100%;
  position: relative;
  width: 100%;
}

.wheel__shadow-top,
.wheel__shadow-bottom {
  background: linear-gradient(
    to bottom,
    rgba(13, 22, 29, 0.9) 0%,
    rgba(13, 22, 29, 0.5) 100%
  );
  left: 0;
  height: calc(42% + 2px);
  width: 100%;
  border-bottom: 0.5px solid #555;
  position: relative;
  margin-top: -2px;
  z-index: 5;
  transform: translateZ(100px);
  -webkit-transform: translateZ(100px);
}

.wheel__shadow-bottom {
  background: linear-gradient(
    to bottom,
    rgba(13, 22, 29, 0.5) 0%,
    rgba(13, 22, 29, 0.9) 100%
  );
  margin-top: 2px;
  border-bottom: none;
  border-top: 0.5px solid #555;
}

.wheel__label {
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  margin-top: 1px;
  margin-left: 5px;
  transform: translateZ(100px);
  -webkit-transform: translateZ(100px);
}

.wheel__slide {
  align-items: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: flex;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
}