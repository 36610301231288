.loader {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid $color-yellow;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}



.circle-spinner.loaderx {
  width: 50px;
  height: 50px;
  & > span::before {
    border-color: white;
    border-width: 4px;
  }
  &.circle-spinner-small {
    width: 30px;
    height: 30px;
    & > span::before {
      border-width: 3px;
    }
  }

}


.spinner{
  width: 300px;
  height: 300px;

  //border: 1px solid #222;
  border-top:1px solid #0FFC8A;
  border-radius: 100%;

  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  margin: auto;

  animation: spin 1s infinite linear;
}

.spin {
  animation: spin 1.5s infinite linear;
}


.circle-spinner {
  > span {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 50%;
      bottom: 50%;
      left: 0;
      transform-origin: 100% 100%;
      &:nth-child(1) {
          transform: rotate(-45deg) skewX(0deg);
      }
      &:nth-child(2) {
          transform: rotate(135deg) skewX(0deg);
      }
      &::before {
          content: '';
          opacity: 1;
          box-sizing: border-box;
          display: block;
          border: solid 3px #0FFC8A;
          width: 200%;
          height: 200%;
          border-radius: 50%;
          transform: skewX(-0deg);
      }
  }
}

.circle-spinner {
  border-radius: 50% / 50%;
  animation-name: spin;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.circle-spinner.loaderx {
  animation-duration: 1000ms;
  &.loader-blue > span::before {
      border-color: $color-blue;
  }
  &.loader-green > span::before {
    border-color: $color-green;
  }
  &.loader-yellow > span::before {
    border-color: $color-yellow;
}
  > span {
      &::before {
          opacity: 1;
      }
  }
}
