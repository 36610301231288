.carousel-next, .carousel-prev {
  &:hover {
    color: $color-blue;
  }
  &.disabled {
    color: $color-white-015;
  }
}

.carousel-current-playing {
  &:after {
    content:'';
    position:absolute;
    border:1px solid rgba(255, 255, 255, 0.65);
    top:0;
    bottom:0;
    left:0;
    right:0;
  }
}

.carousel-selected {
  border-width: 2px;
  &:hover {
    border-width: 2px;
    border-color: $color-green;
  }
}

.moveToLeft {
  animation: slideLeft 1s !important;
}

.moveToRight {
  animation-name: slideRight 1s !important;
}
