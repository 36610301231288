.gm-style iframe + div {
  border: none !important;
}

#map-wrapper.setlist-active {
  .zoom-nav {
    right: 303px;
  }
}
#map-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  .zoom-nav {
    position: absolute;
    bottom: 18px;
    right: 3px;
    z-index: 100;
    transition: all 0.3s;
    overflow: hidden;
    /*
                .btn {
                  font-size:38px;
                  line-height:1;
                  padding: 0px 6px;
                  display:block;
                  color:#fff;

                  transition:background 0.3s, box-shadow 0.3s;
                  -o-transition:background 0.3s, box-shadow 0.3s;
                  -ms-transition:background 0.3s, box-shadow 0.3s;
                  -moz-transition:background 0.3s, box-shadow 0.3s;
                  -webkit-transition:background 0.3s, box-shadow 0.3s;

                  background:rgba(0,42,63,0.9);


                  &.in{

                    border-radius: 10px 10px 0 0;

                  }
                  &.out {
                    border-top:1px solid rgba(0,0,0,0.3);
                    border-radius: 0 0 10px 10px;
                  }
                  &:hover {
                    background:rgba(64,133,167,0.8);


                  }
                  &.disable {
                    color:rgba(225,225,225,0.4);
                    background:transparent;
                  }
                }
                */
  }
}
#map-canvas {
  width: 100%;
  min-height: 100%;
  z-index: 1;
  overflow: hidden;
}
#map-canvas,
.gm-style {
  background-color: $color-pickled-blue;
}

.markerx {
  height: 54px;
  width: 54px;
  position: absolute;
  border-radius: 50%;
  background: #0ffc8a;
  z-index: 2;
  .number {
    content: "";
    border-radius: 50%;
    height: 44px;
    width: 44px;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    color: $color-dark-blue;
  }

  &:not(.marker-cluster) {
    height: 48px;
    width: 48px;
    .number {
      height: 38px;
      width: 38px;
      border: 3px solid $color-dark-blue;
      font-size: 24px;
      padding-bottom: 2px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      background: #0ffc8a;
      transform: rotate(45deg);
      transform-origin: top left;
      z-index: -1;
    }
    /*
    &#marker-active {
      &::after {
      // background: white;
      }
    } */
  }

  &.marker-cluster {
    > .circle-spinner {
      width: 46px;
      height: 46px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  /*&.selected {
  background: #ffae00;
}*/
  &.upcoming {
    z-index: 1;
    background: $color-blue;

    &:not(.marker-cluster) {
      &::after {
        background: $color-blue;
        //background: #38e9fe;
      }
    }

    &#marker-active {
    }
    /*
      pulsating cool effect
      &::after {
          content: '';
          position: absolute;
          left: -3px;
          right: -3px;
          top: -3px;
          bottom: -3px;
          border-radius: 50% / 50%;
          border-color: #38e9fe;
          border-style: solid;
          border-width: 3px;
          pointer-events: none;
          animation: bounce-2 2s infinite;
      }*/
  }

  &.focused {
    background: $color-yellow;

    &:not(.marker-cluster) {
      &::after {
        background: $color-yellow;
        //background: #38e9fe;
      }
    }
  }

  &.highlight,
  &.focused {
    .number {
      background: white;
    }
    &:not(.marker-cluster) {
      &::after {
        //background: #38e9fe;
      }
    }
  }
}

#marker-active .circle-spinner > span::before {
  opacity: 1;
}

.markerx .circle-spinner > span::before {
  //border-color: #38e9fe;
  border-color: black;
}

.markerx.highlight .circle-spinner > span::before {
  //border-color: #38e9fe;
}

.event-popup {
  clip-path: polygon(
    100% 0,
    100% calc(100% - 16px),
    calc(50% + 16px) calc(100% - 16px),
    50% 100%,
    calc(50% - 16px) calc(100% - 16px),
    0 calc(100% - 16px),
    0 0
  );
  width: 320px;
  cursor: default;
  animation: fadeInUp 0.3s 1;
  transform-origin: center bottom;
  z-index: 4;
  overflow: hidden;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 6px;
    height: 32px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
    pointer-events: none;
  }
  hr {
    position: absolute;
    width: 100%;
    height: 16px;
    bottom: 2px;
    border: 0;
    left: 50%;
    pointer-events: none;
    &:after,
    &:before {
      content: "";
      position: absolute;
      height: 18px;
      width: 100%;
      z-index: 1;
    }
    &:before {
      left: calc(-100% + 3px);
      border-top: 2px solid #ccc;
      border-right: 3px solid #ccc;
      transform-origin: left bottom;
      transform: skewX(45deg);
    }
    &:after {
      right: 3px;
      border-top: 2px solid #ccc;
      border-left: 3px solid #ccc;
      transform-origin: right bottom;
      transform: skewX(-45deg);
    }
  }
  &:not(.event-popup-bottom) .event-popup-list li:last-child .event-button {
    padding-bottom: 2rem;
    &::after {
      margin-bottom: 1rem;
    }
  }
  &.event-popup-bottom {
    clip-path: polygon(
      calc(50% + 16px) 16px,
      100% 16px,
      100% 100%,
      0 100%,
      0 16px,
      calc(50% - 16px) 16px,
      50% 0
    );
    border: 2px solid #ccc;
    &.event-popup-hover-top {
      clip-path: none !important;
      hr {
        visibility: hidden;
      }
    }
    .event-popup-list li:first-child {
      padding-top: 16px;
    }
    hr {
      bottom: auto;
      top: -2px;
      &:before {
        left: calc(-100% + 3px);
        transform-origin: left top;
        transform: skewX(-45deg);
        border-bottom: 2px solid #ccc;
      }
      &:after {
        transform-origin: right top;
        transform: skewX(45deg);
        border-bottom: 2px solid #ccc;
      }
    }
  }
}

.event-popup-hover-top,
.event-popup-hover-bottom {
  &:before,
  &:after {
    margin-right: -5px;
    opacity: 1;
    pointer-events: all;
  }
  &:before {
    background-size: 12px;
    background-repeat: no-repeat;
    z-index: 2;
  }
}

.event-popup-hover-top {
  &:before,
  &:after {
    bottom: auto;
    top: 0;
  }
  &:after {
    background: linear-gradient(
      0deg,
      rgba(14, 25, 33, 0) 0%,
      rgba(14, 25, 33, 0.65) 50%
    );
  }
  &:before {
    background-image: url(../assets/images/arrow-up.svg);
    background-position: center 8px;
  }
}

.event-popup-hover-bottom {
  &:not(.event-popup-bottom) {
    clip-path: none !important;
    hr {
      visibility: hidden;
    }
  }
  border-bottom: 2px solid #ccc;
  &:before,
  &:after {
    bottom: 0;
    top: auto;
  }
  &:after {
    background: linear-gradient(
      0deg,
      rgba(14, 25, 33, 1) 0%,
      rgba(14, 25, 33, 0) 70%
    );
  }
  &:before {
    background-image: url(../assets/images/arrow-down.svg);
    background-position: center 12px;
  }
}

.event-popup-list {
  margin: 2px 2px;
}

.markerx {
  &::before {
    pointer-events: none;
  }
  &.marker-pop-top::before {
    top: -100%;
  }
  &.marker-pop-bottom::before {
    bottom: -100%;
  }
  &#marker-active::before {
    pointer-events: all;
  }
}

.markerx.marker-cluster-sm {
  position: relative;
  height: 41px;
  width: 41px;
  > .circle-spinner {
    width: 34px;
    height: 34px;

    > span::before {
      border-width: 2px;
    }
  }
  .number {
    height: 34px;
    width: 34px;
    font-size: 1.25rem;
  }
}

.markerx.marker-cluster-xs {
  position: relative;
  height: 32px;
  width: 32px;
  > .circle-spinner {
    width: 26px;
    height: 26px;

    > span::before {
      border-width: 1.5px;
    }
  }
  .number {
    height: 26px;
    width: 26px;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.markerx.marker-sm {
  position: relative;
  margin-top: 0.15rem;
  height: 42px;
  width: 42px;
  .number {
    height: 32px;
    width: 32px;
    font-size: 1.2rem;
    border-width: 3px;
  }
}
