.social-auth {
    /*
      .btn-lock-social {
          border: 0;
          border-radius: 0;
          display: block;
          transition: background .3s;
      }
      .btn-lock-social-icon {
          line-height: 2.5rem;
      }
      .btn-lock-social-text {
          box-sizing: border-box;
          //padding: 5px 15px 5px auto;
          line-height: 30px;
          text-align: left;
          text-transform: uppercase;
          color: #fff;
      }
      */
      .btn-lock-social[data-provider^=facebook] {
          background-color: #3b5998;
      }
      .btn-lock-social[data-provider^=google] {
          background-color: #dd4b39;
      }
      .btn-lock-social[data-provider^=twitter] {
          background-color: #55acee;
      }
  }
  .copy-separate {
      position: relative;
      margin: 15px 0;
      &:before {
          height: 1px;
          background-color: #208650;
          margin: 10px 0;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          width: 100%;
      }
      p {
          background-color: rgb(18, 18, 18);
          margin-top: -25px;
          display: table;
          margin: 0 auto;
          position: relative;
          z-index: 1;
          padding: 0 10px;
      }
  }