.menu-item,
.menu-label {
    &::after {
        //background: rgb(157, 246, 228);
        content: '';
        height: 250px;
        left: -130px;
        opacity: .3;
        position: absolute;
        top: -60px;
        transform: rotate(45deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: -10;
        background: white;
    }
    &:not(.selected):hover {
        &::after {
            background: white;
            left: 120%;
            //transition: background-color 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: .4;
        color: #999;
    }
}
.auth-signup {
  .form-input {
    margin-top:1rem;
  }
  .form-input.value {
    margin-top:2rem;
  }
}

// stay
.form-label {
    transform: scale(1) translate3d(0.5rem,0,0);
    transform-origin: left center;
    transition: transform .3s ease;
  }
  .form-input.focus,
  .form-input.value {
      .form-label {
          transform: scale(0.9) translate3d(0,-100%,0);
          font-weight: 400;
          color: #fff;
      }
  }

.profile-picture {
  input[type="file"]{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
}

.filters {
  [type="checkbox"]:checked + label {
    opacity:1;
  }
}

.menu-label,
.item-select {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 2px;
        transition: width 100ms cubic-bezier(0.19, 1, 0.22, 1);
        background: white;
    }
}
.menu-label:hover {
    //z-index: 1000;
    background: $color-white-015;
}

[type="checkbox"]:checked + .menu-label,
[type="radio"]:checked + .menu-label,
//.menu-item > .track-content.active,
.menu-item.active {
    &::after {
        left: -115px;
        opacity: 1;
    }
    &::before {
        transition: width 350ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 100%;
        //box-shadow: 0 0 15px rgba(15, 252, 138, 0.8);
    }
}
.item-select > a {
    padding-top: 16px;
    padding-bottom: 16px;
}
.item-select.active a {
    color: #0FFC8A;
}
/*
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}
*/
.filter-label {
  font-family: 'Oswald', sans-serif;
  margin-bottom:1px;
  opacity:0.4;
  &:hover {
    opacity:1;
    background-color: rgba(44, 235, 134, .2);
  }
}
[type="checkbox"]:checked + .filter-label,
[type="radio"]:checked + .filter-label {
  opacity:1
}


.event-filter-radio {
  label {
    padding-left: 3.45rem;
    font-size: 1.15rem;
  }
  label.active::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    left: 22px;
    border-radius: 50%;
    background-color: $color-green;
    transform: scale(1);
    transition: all ease 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    left: 22px;
    border-radius: 50%;
    background-color: transparent;
    transform: scale(0);
  }

  &::before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    left: 18px;
    border-radius: 50%;
    background-color: transparent;
    border: .125rem solid $color-green;
  }

  &.event-filter-radio-upcoming {
    &::before {
      border-color: $color-blue;
    }

    label.active::after {
      background-color: $color-blue;
    }
  }
}

.pagination-sm {
  .pagination-first.disabled, .pagination-last.disabled {
    display: none;
  }
}

.noUi-target.slider {
  &.noUi-target {
    background: none;
    border: 0;
    box-shadow:none;
    height: 0.5rem;
    .noUi-base {
      background: #eee;
      border: none;
      height: 5px;
      border-radius: 5px;

     &:after {
        content: '';
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: 0;
        right: 0;
      }
    }
  }
  .noUi-connect {
    background-color: $color-yellow;
  }
  .noUi-handle {
    height: 30px;
    width: 6px;
    top: -12px;
    left: -3px;
    border: none;
    border-radius: 3px;

box-shadow:0px 0px 4px 2px rgba( 0, 0, 0, 0.5 );

    &:after, &:before {
      content: none;
   }
  }
}

.query-nav {
  hr {
    height: .25rem;
    width: 50%;
    margin: 0;
    background: $color-yellow;
    border: none;
    transition: 150ms;
  }
  li:nth-child(2).selected ~ hr {
    margin-left: 50%;
  }
}

.sidebar {
  .query-nav {
    hr {
      height: .15rem;
      background: $color-blue;
    }
    li:nth-child(2).selected ~ hr {
      background: $color-green;
    }
}
}

input:focus::placeholder {
  color: transparent;
}
