@-moz-keyframes pivot {
  0% {
    -moz-transform: rotate(0deg);
}
50% {
    -moz-transform: rotate(45deg);
}
100% {
  -moz-transform: rotate(0deg);
}
}
@-webkit-keyframes pivot {
  0% {
    -webkit-transform: rotate(0deg);
}
50% {
    -webkit-transform: rotate(45deg);
}
100% {
  -webkit-transform: rotate(0deg);
}
}
@keyframes pivot {
  0% {
      transform:rotate(0deg);
  }
  50% {
      transform:rotate(45deg);
  }
  100% {
    transform:rotate(0deg);
}
}

@keyframes pulsate {
  0% {
      transform: scale(0, 0);
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translate3d(0, 10px, 0);
  }
  to {
      opacity: 1;
      transform: none;
  }
}
@keyframes fadeInDown {
  from {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
  }
  to {
      opacity: 1;
      transform: none;
  }
}

@-moz-keyframes spin {
  0% {
      -moz-transform: rotate(0deg);
  }
  100% {
      -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
   }	   }
