
  .w-1point5 {
    width: 1.5rem;
  }
  .w-2point5 {
    width: 2.5rem;
  }
  .w-3 {
    width: 3rem;
  }
  .w-5 {
    width: 5rem;
  }
  .w-2-5 {
    width: 2.5%;
  }
  .w-95 {
    width: 95%;
  }

  .w10 {
    width: 10rem;
  }

  .w-16 {
    width: 16.66667%;
  }
  .w-18 {
    width: 18rem;
  }
  .w-20 {
    width: 20rem;
  }
  .w-300 {
      width: 300px;
  }
  .w-320 {
    width: 320px;
  }
  .w-200 {
    width: 200px;
}

  .min-w-2 {
    min-width: 2rem;
  }
  .mw-20 {
    max-width: 20rem;
  }
  .mw-9 {
    max-width: 9rem;
  }

.h-1point5 {
  height:1.5rem;
}
  .h-2point5 {
    height: 2.5rem;
  }
  .h-3 {
    height: 3rem;
  }
  .mh--1 {
    margin-left:-0.25rem;
    margin-right:-0.25rem;
  }
  .mr-3 {
    margin-right: 3rem;
  }
  .pa05 {
    padding: 0.125rem;
  }
  .pa2point5 {
    padding: 0.75rem;
  }
  .pl1point5 {
    padding-left: 1.5rem;
  }
  .pl-3 {
    padding-left: 3rem;
  }
  .pl45 {
    padding-left: 3rem;
  }
  .pv2-5 {
    padding-top: .65rem;
    padding-bottom: .65rem;
  }
  .pb-05 {
    padding-bottom: .05rem;
  }
  .pb-3 {
    padding-bottom: 3rem;
  }
  @media screen and (min-width: 30em) {
    .pb0-ns {
      padding-bottom: 0;
    }
  }
  .pr-1point5 {
    padding-right: 1.5rem;
  }
  .top-4 {
    top: 4rem;
  }
  .top-7 {
    top: 7rem;
  }
  .bottom-3 {
    bottom: 3rem;
  }
  .bottom-4 {
    bottom: 4rem;
  }
  .fill-yellow {
    fill: $color-yellow;
  }
  .fill-white path {
    fill: white;
  }
  .fill-darkest-blue path {
    fill: $color-darkest-blue;
  }
  .fill-blue {
    fill: $color-blue;
  }
  .fill-green {
    fill: $color-green;
  }
  .stroke-blue {
    stroke: $color-blue;
  }
  .yellow {
    color: $color-yellow;
  }
  .green {
    color: $color-green;
  }
  .blue {
    color: $color-blue;
  }
  .bg-blue {
    background-color: $color-blue;
  }
  .bg-green {
    background-color: $color-green;
  }
  .bg-yellow {
    background-color: $color-yellow;
  }
  .bg-mid-blue {
   background-color: $color-mid-blue;
  }
  .bg-mid-blue-transparent {
    background-color: $color-mid-blue-transparent;
  }
  .bg-white-025 {
    background-color: hsla(0, 0%, 100%, .025);
  }
  .b--blue {
    border-color: $color-blue;
  }
  .b--green {
    border-color: $color-green;
  }
  .b--yellow {
    border-color: $color-yellow;
  }
  .b--mid-blue {
    border-color: $color-mid-blue;
  }
  .br-50 {
    border-radius: 50%;
  }
  .br-3 {
    border-radius: 3rem;
  }
  .br-5 {
    border-radius: 5rem;
  }
  .object-fit-cover {
    object-fit: cover;
  }
  .pe-none {
    pointer-events: none;
  }
  .pe-all {
    pointer-events: all;
  }
  .z-1039 {
    z-index: 1039;
  }
  .z-1051 {
    z-index: 1051;
  }
  .cursor-none {
    cursor: none;
  }
  .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

  .aspect-ratio-image {
      position:absolute;
      top:50%;
      left:50%;
      width:100%;
      transform:translate(-50%, -50%);
    }
  .roboto {
    font-family: 'Poppins', sans-serif;
  }
  .oswald {
  font-family: 'Oswald', sans-serif;
  }
  .f2point5 {
    font-size: 2.5rem;
  }
  .f8 {
    font-size: 0.6875rem;
  }
  .f9 {
    font-size: 0.55rem;
  }
  @media screen and (min-width: 30em) {
    .f7-ns {
      font-size: .75rem;
    }
  }

  .darkest-blue {
    color: $color-darkest-blue;
  }
  .color-dark {
    color: $color-darkest-gray;
  }

.color-green {
    color: $color-green;
  }

  .color-blue {
    color: $color-blue;
  }

  .color-deep-red {
    color: #ff0000;
  }

  .color-red {
    color: $color-red;
  }

  .color-dark-blue {
    color: $color-dark-blue;
  }

  .color-dark-blue {
    color: $color-dark-blue;
  }

  .color-darkest-blue {
    color: $color-darkest-blue;
  }

  .color-yellow {
    color: $color-yellow;
  }

  .color-dark-blue {
    color: $color-dark-blue;
  }

  .bg-tm {
    background-color: #026CDF;
  }

  .bg-off-black {
    background-color: $color-darkest-gray;
  }
  .bg-dark-blue {
    background-color: $color-dark-blue;
  }

  .bg-darkest-blue {
    background-color: $color-darkest-blue;
  }

  .bg-dark-blue-transparent {
    background-color: $color-dark-blue-transparent;
  }

  .bg-darkest-blue-transparent {
    background-color: $color-darkest-blue-transparent;
  }

  @media screen and (min-width: 30em) {
    .bg-dark-blue-transparent-ns {
      background-color: $color-dark-blue-transparent;
    }
    .bg-darkest-blue-transparent {
      background-color: $color-darkest-blue-transparent;
    }
    .bg-transparent-ns {
      background-color: transparent;
    }
  }

  .top--100 {
    transform: translateY(-100%);
  }
  .no-transition {
    transition-property: none !important;
}

.sticky {
  position: sticky;
}

.animate {
  transition: transform 0.5s ease;
}
.pointer-events-all {
  pointer-events: all;
}
.pointer-events-none {
  pointer-events: none;
}
@media screen and (min-width: 30em) {
  .pointer-events-none-ns {
    pointer-events: none;
  }
}

[disabled] {
  cursor: not-allowed;
}

.stubborn {
  flex-shrink: 0;
}