.progress-top-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 1051;
}
.progress-bar {
    width: 0px;
    height: 3px;
    background-image: radial-gradient(farthest-corner at 0 100%, #9DF6E4 30%, #0FFC8A);
    transition: width 250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.progress-top-wrapper {
    .progress-bar {
        height: 4px;
    }
}
.progress-password-wrapper {
    margin-top: 6px;
    padding-bottom: 20px;
    .progress {
        background: #e3e3e3;
    }
    .progress-bar {
        > span {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 12px;
            font-weight: 300;
        }
    }
}
.reset .progress-bar {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    width: 0px;
}


progress {
    border: none;
    width: 100%;
    height: 0.3rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
  }
  
  progress::-moz-progress-bar {
    background: $color-darkest-blue;
  }
  
  progress::-webkit-progress-value {
    transition: width 500ms cubic-bezier(0,.72,.2,.98);
    background: $color-yellow;
  }

  progress.reset::-webkit-progress-value {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
    width: 0px;
  }
  
  progress::-webkit-progress-bar {
    background: transparent;
  }



